import Statement from "../sections/catalogue/statement";
import { useEffect, useState } from "react";
import Button from "../components/button/Button";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/hero_images/herocatalogue4.svg";
import InfiniteScrollBar from "../components/loader/Loader";
import ImageSlider from "../components/imageSlider/ImageSlider";
import VideoSlider from "../components/videoSlider/videoSlider2";
import BoxDelivery from "../sections/catalogue/Boxdelivery";
import BoxEnablers from "../sections/catalogue/Boxenablers";
import BoxGallery from "../sections/catalogue/Boxgallery";
import BoxVideo from "../sections/catalogue/BoxVideo";
import fetchdata from "../service/catalogue";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import Hero from "../components/hero/hero";
const Catalogue = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const [loading, setLoading] = useState(true);
  const [catalogueVideo, setCatalogueVideo] = useState<any>({});
  const [catalogueGallery, setCatalogueGallery] = useState<any>([]);
  const [statement, setStatement] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [hero, setHero] = useState<any>({});
  const [images, setImages] = useState<any>([]);
  const {
    i18n: { language },
  } = useTranslation();
  const fetch = async () => {
    setLoading(true);
    await fetchdata(language)
      .then((res: any) => {
        setHero(res?.hero);
        setStatement(res?.statement);
        setCatalogueGallery(res?.catalogueGallery);
        setCatalogueVideo(res?.catalogueVideo);
        setData(res?.data);
        setImages(res?.images);
        setLoading(false);
      })
      .catch(console.error);
  };
  useEffect(() => {
    fetch();
  }, [language]);

  if (loading) return <InfiniteScrollBar />;
  return (
    <div>
      <Helmet>
        {/* <title>Afracad | catalogue</title> */}
        <meta name="title" content="Afracad | catalogue" />
        <meta name="description" content={hero?.description} />
      </Helmet>
      <section className="hidden lg:block relative">
        <Hero hero={"catalogue"}>
          <foreignObject
            x="121.12"
            y="114.2"
            width="100%"
            height="170"
            className="overflow-hidden"
          >
            <h1
              style={{ color: hero?.color_title_1 }}
              className="text-lg font-extrabold text-[#26FFBA]"
            >
              {hero?.title_1_}
            </h1>
          </foreignObject>
          <foreignObject
            x="121.12"
            y="180.96"
            width="50%"
            height="170"
            className="overflow-hidden w-full md:w-1/2"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: hero?.title_2,
              }}
            />
          </foreignObject>
          <foreignObject
            x="121.12"
            y="346"
            width="100%"
            height="170"
            className="overflow-hidden"
          >
            <Button density={"dense"} className="text-4">
              <a href={hero?.button_url}>{hero?.button_text}</a>
            </Button>
          </foreignObject>
        </Hero>
      </section>
      <section className="bg-green-primary block lg:hidden relative">
        <div className="container mx-auto flex flex-col px-10 py-20 w-full  gap-4 h-full z-10">
          <h1
            style={{ color: hero?.color_title_1 }}
            className="text-2xl font-bold uppercase text-green-secondary"
          >
            {hero?.title_1_}
          </h1>
          <div
            className="textfontsubtitle uppercase"
            dangerouslySetInnerHTML={{
              __html: hero?.title_2,
            }}
          />
          <p className="font text-white text-2xl lg:text-xl font-normal ">
            {hero?.description}
          </p>
          <div className="w-fit">
            <a href={hero?.button_url}>
              <Button density={"dense"} className="whitespace-nowrap">
                {hero?.button_text}
              </Button>
            </a>
          </div>
        </div>
      </section>
      <section className="relative section-paddings flex flex-col gap-8 no-btm">
        <div className="container relative">
          <br />
          {images ? (
            <ImageSlider
              header={data?.learning_solutions}
              images={images}
              language={language}
            />
          ) : null}
        </div>
      </section>

      {/* LEARNING DELIVERY */}
      <section className="section-paddings no-btm">
        <div className="container mx-auto flex flex-col gap-5">
          <p className="text-green-title text-[28px] font-bold uppercase leading-[42px]">
            {data?.learning_delivery?.title}
          </p>
          <p className="text-lg font-bold leading-[27px]">
            {data?.learning_delivery?.description}
          </p>
          <div className="w-full flex flex-col lg:flex-row flex-wrap gap-6  justify-center mx-auto">
            <BoxDelivery index={1} data={data?.learning_delivery?.box_1} />
            <BoxDelivery index={2} data={data?.learning_delivery?.box_2} />
            <BoxDelivery index={3} data={data?.learning_delivery?.box_3} />
          </div>
        </div>
      </section>
      {/* Enablers */}
      <section className="section-paddings">
        <div className="container mx-auto flex flex-col gap-5">
          <p className="text-green-title text-[28px] font-bold uppercase leading-[42px]">
            {data?.enablers?.title}
          </p>
          <p className="text-lg font-bold leading-[27px]">
            {data?.enablers?.description}
          </p>
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-6  justify-center mx-auto">
            <BoxEnablers data={data?.enablers?.icons?.icon_1_} />
            <BoxEnablers data={data?.enablers?.icons?.icon_2} />
            <BoxEnablers data={data?.enablers?.icons?.icon_3} />
          </div>
        </div>
      </section>
      {/*WHAT ALUMNI HAVE TO SAY  */}
      <section className="section-paddings">
        <div className="container mx-auto flex flex-col gap-5">
          <p className="text-[#00AA8C] text-[28px] font-bold uppercase leading-[42px]">
            {data?.testimonials?.title}
          </p>
          <p className="lg:w-2/3 text-lg font-bold leading-[27px]">
            {data?.testimonials?.description}
          </p>
          <VideoSlider data={catalogueVideo} />
        </div>
      </section>
      {/*ALUMNI GALLERY  */}
      <section className="section-paddings">
        <div className="container mx-auto flex flex-col gap-5">
          <p className="text-green-title text-[28px] font-bold uppercase leading-[42px]">
            {data?.gallery?.title}
          </p>
          <p className="text-lg font-bold leading-[27px]">
            {data?.gallery?.description}
          </p>
          <BoxGallery data={catalogueGallery} />
        </div>
      </section>
      <section className="">
        <div className="container mx-auto grid lg:grid-cols-4 gap-2">
          <p className="col-span-3 text-justify text-zinc-600 text-lg font-medium leading-relaxed">
            {data?.gallery?.paragraph}
          </p>
          <Button
            classbtn="justify-self-end"
            density={"dense"}
            className=" whitespace-nowrap"
          >
            <a href={data?.gallery?.url_button}>
              {data?.gallery?.button_title}
            </a>
          </Button>
        </div>
      </section>
      <Statement statement={statement} />
    </div>
  );
};

export default Catalogue;
