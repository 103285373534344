import styles from "./styles.module.scss";
import clsx from "clsx";

function Header(props: any) {
  return (
    <div className={clsx(styles.bg, "sm:w-1/2 text-center")}>
      <h1 className="text-4xl text-green-secondary font-bold leading-10 mb-4">
        {props?.title}
      </h1>
      <p className="leading-8 text-white">{props?.text}</p>
    </div>
  );
}

export default Header;
