import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress"; // import Button from "@mui/material/Button";

const InfiniteScrollBar = () => {
  return (
    <Box>
      <LinearProgress
        sx={{
          background: "#D4FFF1",

          "& .MuiLinearProgress-bar": {
            backgroundColor: "#30716E",
            color: "#30716E",
          },
        }}
      />
    </Box>
  );
};

export default InfiniteScrollBar;
