import motif12 from "../../assets/images/motif12.svg";

const PresidentStatement = (props: any) => {
  return (
    <section className="section-paddings">
      <div className="container mx-auto grid grid-cols-1 gap-10 lg:grid-cols-3">
        <div
          className="font text-justify text-zinc-600 text-base font-normal lg:col-span-2"
          dangerouslySetInnerHTML={{
            __html: props.item?.description,
          }}
        />
        <div className="relativel m-auto ">
          <img
            src={motif12}
            alt="motif image"
            loading="lazy"
            className="absolute bottom-0 lg:top-0 m-auto w-[300px] aspect-square -z-10 order-first"
          />
          <img
            src={props.item?.image}
            alt="image president"
            loading="lazy"
            className="w-2/5 md:w-1/3 lg:w-2/5 ml-16 lg:ml-4"
          />
          <div className="flex flex-col mt-6">
            <h5 className="text-green-secondary text-[22px] font-bold tracking-wide">
              {props.item?.full_name}
            </h5>
            <p className="text-teal-700 text-base font-normal tracking-wide">
              {props.item?.profition}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PresidentStatement;
