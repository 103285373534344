import { useState, useEffect } from "react";
import Form from "../sections/contact/Form";
import Footer from "../sections/contact/Footer";
import Header from "../sections/contact/Header";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import InfiniteScrollBar from "../components/loader/Loader";
import axios from "axios";
import { Helmet } from "react-helmet";
function Contact() {
  const {
    i18n: { language },
  } = useTranslation();
  const lng = language === "fr" ? "fr" : "en";
  const [content, setContent] = useState<any>({});
  const [loading, setLoading] = useState(true);
  async function loadContent() {
    await axios
      .get(
        `${process.env.REACT_APP_WORDPRESS}/contact_details?_fields=acf.contact_details_${lng}`
      )
      .then((res) => res.data)
      .then((data) => {
        setContent(data[0].acf?.["contact_details_" + lng]);
        setLoading(false);
      });
  }
  useEffect(() => {
    loadContent();
  }, [language]);
  if (loading) return <InfiniteScrollBar />;
  return (
    <div className="w-full mx-auto relative bg-[#f8fcfc]">
      <Helmet>
        {/* <title>Afracad | catalogue</title> */}
        <meta name="title" content="Afracad | contact us" />
        <meta name="description" content={content?.text_hero} />
      </Helmet>
      <div
        className={clsx(
          styles.bg,
          "m-auto  flex flex-col gap-4 justify-center items-center lg:gap-16 z-0  p-8"
        )}
      >
        <Header title={content?.title_hero} text={content?.text_hero} />
        <Form
          description={content?.details_}
          request_type={Object.values(content?.request_type || {})}
        />
        <Footer title={content?.contact_title} email={content?.email} />
      </div>
    </div>
  );
}

export default Contact;
