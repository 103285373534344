import React, { useRef, useState } from "react";
import iconplay from "../../assets/images/iconplay.svg";
import Player from "../../sections/catalogue/playervideo";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa6";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Videocard from "./videoCard/videoCard";
import "./styles.scss";

const BoxVideo = (props: any) => {
  const [content, setContent] = useState(null);
  const [active, setActive] = useState(-1);
  const slideRef = useRef<any>(null);
  const handleClickScroll = (index: number) => {
    setActive(active === index ? -1 : index);
    const element = slideRef?.current?.children[index];

    if (
      element.offsetLeft <
      slideRef?.current.offsetLeft + slideRef?.current.scrollLeft
    ) {
      slideRef?.current?.scrollTo({
        left: element.offsetLeft - slideRef?.current.offsetLeft - 50,
        behavior: "smooth",
      });
    } else if (
      element.offsetLeft + element.clientWidth >
      slideRef?.current.offsetLeft +
        slideRef?.current.scrollLeft +
        slideRef?.current.clientWidth
    ) {
      slideRef?.current?.scrollTo({
        left:
          element.offsetLeft +
          element.clientWidth -
          (slideRef?.current.offsetLeft + slideRef?.current.clientWidth - 50),
        behavior: "smooth",
      });
    }
  };
  const handleResetScroll = (direction: number) => {
    slideRef?.current?.scrollTo({
      left:
        slideRef?.current?.scrollLeft +
        direction * slideRef?.current?.clientWidth,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="flex flex-row justify-end gap-2 ">
        <button
          onClick={() => handleResetScroll(-1)}
          className="carousel__arrow"
        >
          <IoIosArrowBack className="" />
        </button>
        <button
          onClick={() => handleResetScroll(1)}
          className="carousel__arrow"
        >
          <IoIosArrowForward className="" />
        </button>
      </div>
      <div
        ref={slideRef}
        className="flex flex-nowrap items-center gap-2 lg:h-[32rem] overflow-x-auto"
      >
        {props?.data?.map((item: any, index: number) => (
          <button
            key={item.id}
            onClick={() => setContent(item?.acf)}
            onMouseEnter={() => handleClickScroll(index)}
          >
            <Videocard content={item?.acf} active={active === index} />
          </button>
        ))}
      </div>
      <Player content={content} setContent={setContent} />
    </>
  );
};
export default BoxVideo;
