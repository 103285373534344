import styles from "./SocialMedia.module.scss";
import { Facebook, Twitter, Linkedin } from "./imgs";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Tooltip from "@mui/material/Tooltip";
import { PiLinkBold } from "react-icons/pi";
function SocialMediaShare(props: any) {
  const { t } = useTranslation();
  const { href } = window.location;
  return (
    <div className="inline-flex flex-nowrap jusitfy-start items-center gap-2 my-2">
      <span
        className={clsx(
          "capitalize text-lg font-normal",
          (props.color === "white" || props?.colortext === "white") &&
            "text-white"
        )}
      >
        {t("share")}
      </span>
      <div className="flex justify-center gap-4">
        <Tooltip title={"Facebook"}>
          <FacebookShareButton url={href}>
            <div
              className={clsx(styles.svg, styles.sharedStyle, {
                color: props.color,
              })}
              style={{ color: "#066" }}
            >
              <Facebook />
            </div>
          </FacebookShareButton>
        </Tooltip>
        <Tooltip title={"Linkedin"}>
          <LinkedinShareButton url={href}>
            <div
              className={clsx(styles.svg, styles.sharedStyle, {
                color: props.color,
              })}
              style={{ color: "#066" }}
            >
              <Linkedin />
            </div>
          </LinkedinShareButton>
        </Tooltip>
        <Tooltip title={"Twitter"}>
          <TwitterShareButton url={href}>
            <div
              className={clsx(styles.svg, styles.sharedStyle)}
              style={{ color: "#066" }}
            >
              <Twitter />
            </div>
          </TwitterShareButton>
        </Tooltip>
        <Tooltip title={"Copy"}>
          <div>
            <PiLinkBold
              onClick={() =>
                navigator.clipboard.writeText(window.location.href)
              }
              color="#066"
              className={clsx(styles.svg, styles.sharedStyle, {
                color: "#066",
              })}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default SocialMediaShare;
