import { useState, useEffect } from "react";
import logo from "../../../assets/images/heropress.png";
import image from "../../../assets/images/imagepblication.svg";
import { SelectChangeEvent } from "@mui/material";
import axios from "axios";
import Card from "../../../sections/media_publications/publication/card";
import SearchMedia from "../../../components/searchMedia/searchMedia";
import { useTranslation } from "react-i18next";
import Tags from "../../../components/tags/Tags";
import Archive from "../../../components/achive/Archive";
import RelatedArticle from "../../../components/RelatedArticle/RelatedArticle";
import InfiniteScrollBar from "../../../components/loader/Loader";
import Past from "../../../components/iconpaginate/Past";
import Next from "../../../components/iconpaginate/Next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  changecategory,
  fetchdata,
  filter,
  handlerchange,
} from "../../../service/publication";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import Lottie from "lottie-react";
import nodata from "../../../assets/images/nodata.json";

const Publications = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [items, setItems] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [category, setCategory] = useState("");
  const [years, setYears] = useState("");
  const [categorys, setCategorys] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [random, setRandom] = useState<any>([]);
  const [searchTags, setSearchTags] = useState<any>();
  const [archive, setArchive] = useState("");
  const [title, setTitle] = useState("");
  const [nbPage, setNbPage] = useState(0);
  const handleChange = (event: SelectChangeEvent, isYear = false) => {
    handlerchange(
      event,
      isYear,
      language,
      category,
      search,
      setSearchTags,
      setArchive,
      setYear,
      setSearch,
      setItems
    );
  };
  const handleChangeCategory = (event: SelectChangeEvent) => {
    changecategory(
      event,
      language,
      year,
      search,
      setSearchTags,
      setArchive,
      setItems,
      setCategory
    );
  };
  useEffect(() => {
    fetchdata(
      language,
      setLoading,
      setItems,
      setRandom,
      setYears,
      setCategorys,
      setTitle
    );
  }, [language]);
  const handelfilter = async (etat: boolean) => {
    filter(
      etat,
      language,
      category,
      year,
      search,
      setSearchTags,
      setArchive,
      setItems
    );
  };
  const pagination = async (etat: boolean) => {
    setNbPage(() => (etat ? nbPage + 1 : nbPage - 1));
    axios
      .post(`${process.env.REACT_APP_NODE}/publication/filter`, {
        page: etat ? nbPage + 1 : nbPage - 1,
        lang: language,
        category: category,
        year: year,
      })
      .then((res) => setItems(res.data))
      .catch((err) => console.log(err));
  };
  if (loading) return <InfiniteScrollBar />;
  return (
    <div>
      <Helmet>
        {/* <title>Afracad | catalogue</title> */}
        <meta name="title" content="Afracad | publications" />
        <meta
          name="description"
          content={title
            .replace(/\n/g, " ")
            .replace(/<[^>]+>/g, "")
            .replace(/\s{2,}/g, " ")
            .trim()}
        />
      </Helmet>
      <section className="bg-green-primary lg:bg-transparent relative ">
        <img
          src={logo}
          alt="image hero"
          className="hidden lg:block w-full lg:absolute top-0 -z-10 h-full"
          loading="lazy"
        />
        <div className="section-paddings container mx-auto flex flex-row items-center justify-center h-full lg:pb-32">
          <div className="flex flex-col gap-6">
            <h1 className="text-green-secondary text-xl xl:text-2xl font-normal uppercase tracking-wide">
              Publications
            </h1>
            <div
              className="textfonttitlemedia"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
          <img
            src={image}
            alt="image publication"
            loading="lazy"
            className="hidden lg:block"
          />
        </div>
      </section>

      <section className="section-paddings">
        <SearchMedia
          category={category}
          handleChangeCategory={handleChangeCategory}
          categorys={categorys}
          year={year}
          years={years}
          search={search}
          filter={handelfilter}
          handleChange={handleChange}
          setSearch={setSearch}
        />
      </section>
      <section>
        <div className="container flex flex-row justify-end gap-3">
          <Past
            etat={Math.ceil(items?.count / 5) - 1 >= nbPage && nbPage > 0}
            pagination={pagination}
            isPast={true}
          />
          <Next
            etat={Math.ceil(items?.count / 5) - 1 > nbPage}
            pagination={pagination}
          />
        </div>
      </section>
      <section className="section-paddings ">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 grid-flow-row break-words gap-8">
          <div className="lg:col-span-2 row-span-5">
            {items?.result?.length > 0 ? (
              <div className=" grid grid-cols-2 gap-4 h-fit">
                {items?.result?.map((item: any, index: number) => (
                  <Card item={item} index={index} key={index} />
                ))}
              </div>
            ) : (
              <Lottie
                animationData={nodata}
                loop={false}
                style={{ height: 400 }}
              />
            )}
            <br />
            {items?.count !== 0 && (
              <div className="border-solid border-t-2 border-[#B3B3B3] flex flex-row justify-between pt-4">
                <button
                  className={clsx(
                    Math.ceil(items?.count / 5) - 1 >= nbPage && nbPage > 0
                      ? " text-[#006666] cursor-pointer"
                      : "text-gray-500 cursor-default",
                    "flex flex-row gap-2 items-center"
                  )}
                  onClick={() =>
                    Math.ceil(items?.count / 5) - 1 >= nbPage &&
                    nbPage > 0 &&
                    pagination(false)
                  }
                >
                  <ChevronLeftIcon fontSize="small" />
                  <span>{t("previous")}</span>
                </button>
                <p className="text-[#006666]">
                  Page {nbPage + 1}/{Math.ceil(items?.count / 5)}
                </p>
                <button
                  className={clsx(
                    Math.ceil(items?.count / 5) - 1 > nbPage
                      ? "text-[#006666] cursor-pointer"
                      : "text-gray-500 cursor-default",
                    "flex flex-row gap-2 items-center "
                  )}
                  onClick={() =>
                    Math.ceil(items?.count / 5) - 1 > nbPage && pagination(true)
                  }
                >
                  <span> {t("next")}</span>
                  <NavigateNextIcon fontSize="small" />
                </button>
              </div>
            )}
          </div>
          <Tags
            setData={setItems}
            url={`${process.env.REACT_APP_NODE}/publication/filter`}
            searchTags={searchTags}
            setSearchTags={setSearchTags}
            setCategory={setCategory}
            category={category}
            setYear={setYear}
            year={year}
            setSearch={setSearch}
            setNbPage={setNbPage}
            setArchive={setArchive}
          />
          <Archive
            setData={setItems}
            url={`${process.env.REACT_APP_NODE}/publication/filter`}
            setSearchTags={setSearchTags}
            searchTags={searchTags}
            setSearch={setSearch}
            setCategory={setCategory}
            setYear={setYear}
            setNbPage={setNbPage}
            setArchive={setArchive}
            archive={archive}
          />
          <div className="h-fit border-[#00736E] border-solid border-2 p-4 flex flex-col gap-4">
            <h6 className="text-center text-teal-700 text-xl font-medium uppercase">
              {t("relatedarticle")}
            </h6>
            <RelatedArticle random={random} path="/Publications/" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Publications;
