import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import SocialMedia from "../../../components/socialMedia/SocialMediaShare";
import logo from "../../../assets/images/heropress.png";
import motif3 from "../../../assets/images/motif3.png";
import motif4 from "../../../assets/images/motif4.png";
import { BsCalendar } from "react-icons/bs";
import InfiniteScrollBar from "../../../components/loader/Loader";
import defaultimg from "../../../assets/images/default2.svg";
import clsx from "clsx";
import { fetchdatadetails } from "../../../service/press";

const PressRelease = () => {
  const { id } = useParams();
  const [item, setItem] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    fetchdatadetails(language, id || "", setLoading, setItem, navigate);
  }, [language]);
  return (
    <div>
      {loading ? (
        <InfiniteScrollBar />
      ) : (
        <>
          <section className="bg-green-primary lg:bg-transparent relative">
            <img
              src={logo}
              alt=""
              className="hidden lg:block w-full lg:absolute top-0 -z-10 h-full"
            />
            <div className="section-paddings container mx-auto flex flex-col lg:gap-10 gap-4 h-full lg:px-60">
              <div className="relative">
                <div className="flex flex-row justify-between">
                  <h1 className="text-green-secondary text-xl font-normal uppercase tracking-wide">
                    {t("pressreleases")}
                  </h1>
                  <Link to="/publications">
                    <button className="flex flex-row justify-center items-center bg-transparent border-transparent cursor-pointer">
                      <MdArrowBackIos color="white" />
                      <p className="text-white text-xl font-medium">
                        {t("back")}
                      </p>
                    </button>
                  </Link>
                </div>
                <div className="lg:grid flex flex-col-reverse lg:grid-cols-2 gap-4 mt-5">
                  <div className="w-full h-fit relative">
                    <img
                      src={
                        item?.hero?.image !== "false"
                          ? item?.hero?.image
                          : defaultimg
                      }
                      alt="press"
                      loading="lazy"
                      className={clsx(
                        item?.hero?.image !== "false" && "lg:w-9/12",
                        "object-cover  w-full mr-auto"
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-4 justify-center">
                    {item?.hero?.date && (
                      <p className="text-green-secondary text-lg font-medium flex flex-row gap-2 items-center">
                        <BsCalendar color="#26FFBA" />
                        {" - "}
                        {new Date(item?.hero?.date)?.toLocaleDateString(
                          [language],
                          {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </p>
                    )}
                    <div
                      className="textfontsubtitle"
                      dangerouslySetInnerHTML={{
                        __html: item?.hero?.styled_title,
                      }}
                    />
                    <br className="hidden lg:block" />
                    <br className="hidden lg:block" />
                    <br className="hidden lg:block" />
                    <br className="hidden lg:block" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-paddings">
            <div className="container mx-auto flex flex-col gap-4 max-w-screen-lg">
              <div
                className="font text-zinc-500 text-base font-normal leading-loose text-justify"
                dangerouslySetInnerHTML={{
                  __html: item?.block_1?.paragraph_1,
                }}
              />
              <div>
                {item?.block_1?.image_1 !== "false" && (
                  <div className="relative ">
                    <img
                      src={item?.block_1?.image_1}
                      alt="image block1"
                      loading="lazy"
                      className="rounded float-right h-fit md:pl-4 pb-4"
                    />
                    <img
                      src={motif3}
                      alt="motif image"
                      loading="lazy"
                      className=" hidden lg:block absolute -top-8 -right-2"
                    />
                  </div>
                )}
                <p className="font text-zinc-500 text-base font-normal leading-loose text-justify">
                  {item?.block_1?.paragraph_2.replace(/<[^>]+>/g, "") +
                    " " +
                    item?.block_1?.paragraph_3.replace(/<[^>]+>/g, "")}
                </p>
              </div>
              <div
                className={clsx(
                  item?.block_1?.image_2 !== "false" &&
                    "grid grid-cols-1 lg:grid-cols-2 gap-4"
                )}
              >
                {item?.block_1?.image_2 !== "false" && (
                  <img
                    src={item?.block_1?.image_2}
                    alt="image block1"
                    loading="lazy"
                    className="rounded w-full"
                  />
                )}
                <div
                  className="font text-zinc-500 text-base font-normal leading-loose text-justify"
                  dangerouslySetInnerHTML={{
                    __html: item?.block_1?.paragraph_4,
                  }}
                />
              </div>
              <SocialMedia color="#00736E" />
            </div>
          </section>
          {item?.block_2?.title &&
            item?.block_2?.description &&
            item?.block_2?.button_link &&
            item?.block_2?.button && (
              <section className="section-paddings no-tp">
                <div className="container mx-auto flex flex-col-reverse md:grid grid-cols-2 lg:flex-row max-w-screen-lg">
                  <div className="flex flex-col justify-center items-center gap-4 p-4 lg:p-10 border-2">
                    <h5 className="text-teal-700 text-4xl font-normal capitalize text-center">
                      {item?.block_2?.title}
                    </h5>
                    <p className="text-zinc-500 text-base font-normal leading-loose">
                      {item?.block_2?.description}
                    </p>
                    <Button
                      className="whitespace-nowrap w-full"
                      fullwidth={true}
                      density={"dense"}
                    >
                      <a href={item?.block_2?.button_link}>
                        {item?.block_2?.button}
                      </a>
                    </Button>
                  </div>
                  <img
                    src={item?.block_2?.image}
                    alt="image bock2"
                    loading="lazy"
                    className="w-full"
                  />
                </div>
              </section>
            )}
        </>
      )}
    </div>
  );
};

export default PressRelease;
