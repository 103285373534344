import clsx from "clsx";
import Button from "../../components/button/Button";

const Box = ({ item, index }: any) => {
  return (
    <div className="md:grid grid-cols-2 gap-16">
      <img
        src={item?.image}
        alt="image content"
        loading="lazy"
        className={clsx(
          "hidden md:block m-auto",
          index % 2 === 1 && "order-last"
        )}
      />
      <div className="flex flex-col gap-2 justify-center">
        {item?.title_1 && (
          <h4 className="text-teal-700 text-2xl font-normal uppercase tracking-wide">
            {item?.title_1}
          </h4>
        )}
        {item?.title2 && (
          <div
            className="textfontsubtitle text-4xl"
            dangerouslySetInnerHTML={{
              __html: item?.title2,
            }}
          />
        )}
        {item?.paragraphs && (
          <div
            className="font text-[#636363] text-lg font-normal"
            dangerouslySetInnerHTML={{
              __html: item?.paragraphs,
            }}
          />
        )}
        <div className="mt-4 w-fit">
          {item?.button_text && (
            <a href={item?.button_url}>
              <Button density={"dense"}>{item?.button_text}</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Box;
