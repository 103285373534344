import { SelectChangeEvent } from "@mui/material";
import axios from "axios";

export async function fetchdata(
  language: string,
  setItem: any,
  setRandom: any,
  setCategorys: any,
  setYears: any,
  setTitle: any,
  setLoading: any
) {
  setLoading(true);
  Promise.all([
    axios.get(`${process.env.REACT_APP_NODE}/newsletters/random/${language}`),
    axios.get(`${process.env.REACT_APP_NODE}/misc/categories/${language}`),
    axios.post(`${process.env.REACT_APP_NODE}/newsletters/filter`, {
      lang: language,
    }),
    axios.get(`${process.env.REACT_APP_NODE}/misc/years_filter`),
    axios.get(`${process.env.REACT_APP_WORDPRESS}/hero_newsletters`),
  ])
    .then(([res1, res2, res3, res4, res5]) =>
      Promise.all([res1.data, res2.data, res3.data, res4.data, res5.data])
    )
    .then(([items, category, data, datayear, hero]: any) => {
      setItem(data);
      setRandom(items);
      setCategorys(category);
      setYears(datayear);
      setTitle(
        language === "en"
          ? hero?.[0]?.acf?.hero_newsletters?.title
          : hero?.[0]?.acf?.hero_newsletters?.title_fr
      );
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function filter(
  etat: boolean,
  language: string,
  category: any,
  year: any,
  search: any,
  setSearchTags: any,
  setArchive: any,
  setItem: any
) {
  setSearchTags("");
  setArchive("");
  axios
    .post(`${process.env.REACT_APP_NODE}/newsletters/filter`, {
      lang: language,
      category: category,
      year: year,
      search: etat ? "" : search,
    })
    .then((res) => setItem(res.data))
    .catch((err) => console.log(err));
}

export async function changecategory(
  event: SelectChangeEvent,
  language: string,
  year: any,
  search: any,
  setSearchTags: any,
  setArchive: any,
  setCategory: any,
  setItem: any
) {
  setSearchTags("");
  setArchive("");
  const categoryshearch: any = event?.target?.value
    ? event?.target?.value
    : event;
  setCategory(categoryshearch);
  axios
    .post(`${process.env.REACT_APP_NODE}/newsletters/filter`, {
      lang: language,
      category: categoryshearch,
      year: year,
      search: search,
    })
    .then((res) => setItem(res.data))
    .catch((err) => console.log(err));
}

export async function handlerChange(
  event: SelectChangeEvent,
  isYear: Boolean,
  language: string,
  category: any,
  search: any,
  setSearchTags: any,
  setArchive: any,
  setYear: any,
  setItem: any,
  setSearch: any
) {
  setSearchTags("");
  setArchive("");
  if (isYear) {
    const yearshearch: any = event?.target?.value
      ? event?.target?.value
      : event;
    setYear(yearshearch);
    axios
      .post(`${process.env.REACT_APP_NODE}/newsletters/filter`, {
        lang: language,
        category: category,
        year: yearshearch,
        search: search,
      })
      .then((res) => setItem(res.data))
      .catch((err) => console.log(err));
  } else {
    setSearch(event?.target?.value);
  }
}

export async function fetchdatadetail(
  language: string,
  id: string,
  setContent: any,
  setLoading: any,
  navigate: any
) {
  await axios
    .get(`${process.env.REACT_APP_NODE}/newsletters/${language}/${id}`)
    .then((response) => {
      if (response.data) {
        setContent(response.data);
        setLoading(false);
      } else {
        return navigate("/newsletters");
      }
    })
    .catch((error) => console.log(error));
}
