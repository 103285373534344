import axios from "axios";

async function fetchdata() {
  let res = {};
  await Promise.all([axios.get(`${process.env.REACT_APP_WORDPRESS}/AfCFTA`)])
    .then(([res1]) => Promise.all([res1.data]))
    .then(([data1]) => {
      res = {
        data: data1[0]?.acf,
      };
    })
    .catch((error) => {
      console.error({ error });
    });
  return res;
}
export default fetchdata;
