import style from "./styles.module.scss";
const VideoPlayer = ({ embedId }: { embedId: string }) => {
  return (
    <div className={style["video-responsive"]}>
      <iframe
        width="100%"
        src={embedId}
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default VideoPlayer;
