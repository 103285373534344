import clsx from "clsx";
import style from "./styles.module.scss";
const Button = (props: any) => (
  <div
    className={clsx(
      props?.fullwidth ? "w-full" : "w-fit",
      "bg-white",
      props?.classbtn
    )}
  >
    <button
      {...props}
      className={clsx(
        style.btn,
        props?.outlined ? style.outlined : style.contained,
        props?.className,
        style?.[props?.density],
        props.semiDense && style.semiDense,
        "w-full h-full"
      )}
    >
      {props.children}
    </button>
  </div>
);

export default Button;
