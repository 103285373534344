import { useEffect, useState } from "react";
import image from "../assets/images/privancynotice.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import InfiniteScrollBar from "../components/loader/Loader";
const PrivacyNotice = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const [item, setItem] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const fetchapi = async () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_WORDPRESS}/Privacy_notice?_fields=acf.privacy_notice_${language}`
      )
      .then((response: any) => {
        setItem(response.data?.[0]?.acf?.[`privacy_notice_${language}`]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchapi();
  }, [language]);
  if (loading) return <InfiniteScrollBar />;
  return (
    <div>
      <section className="bg-green-primary lg:bg-transparent relative">
        <img
          src={image}
          alt="image hero"
          className="hidden lg:block w-full"
          loading="lazy"
        />
        <div className="section-paddings lg:absolute top-0 lg:top-[12%] z-10 text-center mx-auto flex justify-center w-full">
          <h1 className="text-white text-4xl w-full">{item?.title_hero}</h1>
        </div>
      </section>
      <section className="section-paddings lg:no-tp">
        <div className="container flex flex-col gap-8">
          <div
            className="textfont"
            dangerouslySetInnerHTML={{
              __html: item?.privacy_notice,
            }}
          />
          <div
            className="textfont"
            dangerouslySetInnerHTML={{
              __html: item?.terms,
            }}
          />
        </div>
      </section>
    </div>
  );
};
export default PrivacyNotice;
