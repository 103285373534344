import BoxTestImonials from "./boxTestimoials";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
const Testimonials = (props: any) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
  };
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const length = props.items.find((item: any) => item.length > 310);

  const slider = React.useRef<Slider>(null);
  const next = () => slider?.current && slider?.current.slickNext();
  const previous = () => slider?.current && slider?.current.slickPrev();
  return (
    <section className="section-paddings mb-4">
      <div className="container mx-auto flex flex-col gap-5">
        <h5 className="text-emerald-500 text-xl md:text-2xl font-normal ">
          {t("titretestimonials")}
        </h5>
        <p className="text-2xl md:text-4xl font-bold ">
          {t("desctestimonials")}
          <span className="text-green-title">{t("experience")}</span>
        </p>
        <div className=" flex flex-row justify-end gap-3">
          <svg
            width="56"
            height="57"
            viewBox="0 0 56 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              previous();
            }}
            className="cursor-pointer"
          >
            <path
              d="M25.3477 27.9789C25.1016 28.2523 25.1016 28.6625 25.3477 28.9086L29.0664 32.6273C29.3398 32.9008 29.75 32.9008 29.9961 32.6273L30.625 32.0258C30.8711 31.7523 30.8711 31.3422 30.625 31.0961L27.9727 28.4437L30.625 25.8187C30.8711 25.5726 30.8711 25.1351 30.625 24.889L29.9961 24.2601C29.75 24.014 29.3398 24.014 29.0664 24.2601L25.3477 27.9789Z"
              fill={"#30716E"}
            />
            <rect
              x="0.5"
              y="1.19373"
              width="55"
              height="55"
              rx="27.5"
              stroke="#30716E"
            />
          </svg>
          <svg
            width="56"
            height="57"
            viewBox="0 0 56 57"
            fill="none "
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => next()}
            className="cursor-pointer"
          >
            <path
              d="M30.625 28.9086C30.7344 28.7992 30.8164 28.6351 30.8164 28.4437C30.8164 28.2797 30.7344 28.1156 30.625 27.9789L26.9062 24.2601C26.7695 24.1508 26.6055 24.0687 26.4414 24.0687C26.25 24.0687 26.0859 24.1508 25.9766 24.2601L25.375 24.889C25.2383 25.0258 25.1836 25.1898 25.1836 25.3539C25.1836 25.5453 25.2383 25.7094 25.375 25.8187L28 28.4437L25.375 31.0687C25.2383 31.2054 25.1836 31.3695 25.1836 31.5336C25.1836 31.725 25.2383 31.889 25.375 31.9984L25.9766 32.6273C26.0859 32.764 26.25 32.8187 26.4414 32.8187C26.6055 32.8187 26.7695 32.764 26.9062 32.6273L30.625 28.9086Z"
              fill="#30716E"
            />
            <rect
              x="0.5"
              y="1.19373"
              width="55"
              height="55"
              rx="27.5"
              stroke="#30716E"
            />
          </svg>
        </div>
        <Slider {...settings} ref={slider}>
          {props.items?.map((item: any) => (
            <BoxTestImonials key={item.image} item={item} length={length} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
