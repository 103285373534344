import { useForm, Controller } from "react-hook-form";
import { TextField, MenuItem } from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useTranslation } from "react-i18next";
import contriesfr from "../../assets/countries/fr/countries.json";
import contriesen from "../../assets/countries/en/countries.json";
import Swal from "sweetalert2";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import ReCAPTCHA from "react-google-recaptcha";
interface IFormInput {
  name: string;
  email: string;
  phone: string;
  country: string;
  message: string;
  requestType: string;
  recaptcha: string;
}
const FormContact = (props: any) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const requestTypes = props?.request_type || [];
  const countriesList = language === "fr" ? contriesfr : contriesen;
  const [loading, setLoading] = useState<any>("");
  const handleFocus = () => {
    console.log("ReCAPTCHA focus triggered");
  };
  const { register, control, handleSubmit, formState, setError, reset } =
    useForm<IFormInput>({
      defaultValues: {
        name: "",
        email: "",
        phone: "",
        country: "",
        message: "",
        requestType: "",
        recaptcha: "",
      },
      mode: "all",
    });
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3ByZXByb2Qtc3RlcHMudG4vYWZyYWNhZCIsImlhdCI6MTY5MDc5NjY4MSwibmJmIjoxNjkwNzk2NjgxLCJleHAiOjE2OTE0MDE0ODEsImRhdGEiOnsidXNlciI6eyJpZCI6IjIifX19.Qp8eX5Y18JZt_xLM9XzypC6t3VEyT6y4nD2aokc211A";
  const onSubmit = async (data: any) => {
    if (!data.recaptcha) {
      setError("recaptcha", {
        type: "manual",
        message: "ReCAPTCHA is required",
      });
      return;
    }
    setLoading(true);
    const formadata = new FormData();
    formadata.append("text", data?.name);
    formadata.append("email", data?.email);
    formadata.append("uacf7_country_dropdown", data?.country);
    formadata.append("tel", data?.phone);
    formadata.append("menu", data?.requestType);
    formadata.append("textarea", data?.message);
    formadata.append("_wpcf7_unit_tag", "wpcf7-f2991-p2926-o1");
    await axios
      .post(
        "https://preprod-steps.tn/afracad/wp-json/contact-form-7/v1/contact-forms/2890/feedback",
        formadata,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          Swal.fire("Success", t("seccmodal"), "success");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("errmodal"),
          });
        }
        reset({
          name: "",
          email: "",
          phone: "",
          country: "",
          message: "",
          requestType: "",
          recaptcha: "",
        });
      })
      .catch((err) => console.log(err));
  };
  console.log(process.env.REACT_APP_RECAPTCHA_KEY);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="md:grid flex flex-col mx-auto grid-cols-2 gap-4 p-4 md:p-8 rounded-lg shadow md:w-2/3 bg-white"
    >
      <TextField
        required
        size="small"
        label={t("contact.form.name")}
        {...register("name", {
          required: t("messageErrForm"),
          pattern: {
            message: "Invalid name",
            value: /^[a-z ,.'-] || [A-Z ,.'-]$/,
          },
        })}
        sx={{
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#30716E",
            },
        }}
        InputLabelProps={{
          sx: { color: "#666666", "&.Mui-focused": { color: "#30716E" } },
        }}
        error={!!formState.errors.name}
        helperText={formState.errors.name?.message}
        margin="dense"
      />
      <TextField
        required
        size="small"
        label={t("contact.form.email")}
        {...register("email", {
          required: t("messageErrForm"),
          pattern: {
            message: "Invalid email",
            value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
          },
        })}
        sx={{
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#30716E",
            },
        }}
        InputLabelProps={{
          sx: { color: "#666666", "&.Mui-focused": { color: "#30716E" } },
        }}
        error={!!formState.errors.email}
        helperText={formState.errors.email?.message}
        margin="dense"
      />
      <TextField
        required
        size="small"
        label={t("contact.form.country")}
        select
        {...register("country", {
          required: t("messageErrForm"),
        })}
        sx={{
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#30716E",
            },
        }}
        InputLabelProps={{
          sx: { color: "#666666", "&.Mui-focused": { color: "#30716E" } },
        }}
        error={!!formState.errors.country}
        helperText={formState.errors.country?.message}
        margin="dense"
        defaultValue={""}
      >
        {countriesList?.map((country) => (
          <MenuItem key={country.name} value={country.name}>
            {country.name}
          </MenuItem>
        ))}
      </TextField>
      <Controller
        control={control}
        name="phone"
        rules={{
          required: false,
          validate: (value) => matchIsValidTel(value, {}) || value === "",
        }}
        render={({
          field: { ref: fieldRef, value, ...fieldProps },
          fieldState,
        }) => (
          <MuiTelInput
            label={t("contact.form.phone")}
            margin="dense"
            {...fieldProps}
            required={false}
            size="small"
            value={value ?? ""}
            inputRef={fieldRef}
            helperText={
              fieldState.invalid && value !== "" ? t("INVALID_PHONE") : ""
            }
            error={fieldState.invalid && value !== ""}
            sx={{
              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#30716E",
                },
            }}
            placeholder={t("placeholderphone")}
            InputLabelProps={{
              sx: { color: "#666666", "&.Mui-focused": { color: "#30716E" } },
            }}
          />
        )}
      />
      <TextField
        required
        size="small"
        label={t("contact.form.request")}
        select
        {...register("requestType", {
          required: t("messageErrForm"),
        })}
        sx={{
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#30716E",
            },
        }}
        InputLabelProps={{
          sx: { color: "#666666", "&.Mui-focused": { color: "#30716E" } },
        }}
        error={!!formState.errors.requestType}
        helperText={formState.errors.requestType?.message}
        margin="dense"
        defaultValue={""}
      >
        {requestTypes?.map((type: string) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        size="small"
        label={t("contact.form.message")}
        {...register("message", {
          required: t("messageErrForm"),
        })}
        className="col-span-2"
        sx={{
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#30716E",
            },
        }}
        InputLabelProps={{
          sx: { color: "#666666", "&.Mui-focused": { color: "#30716E" } },
        }}
        multiline
        rows={4}
        error={!!formState.errors.message}
        helperText={formState.errors.message?.message}
        margin="dense"
      />
      <Controller
        control={control}
        name="recaptcha"
        render={({ field }) => (
          <div>
            <ReCAPTCHA
              hl={language}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
              {...field}
            />
            {formState.errors.recaptcha && (
              <span className="text-[#D32F2F] text-xs">
                {t("messageErrForm")}
              </span>
            )}
          </div>
        )}
      />
      <div className="col-span-2 ">
        <p className="font-normal text-base text-slate-500">
          {props?.description}
        </p>
        <button
          type="submit"
          className={clsx(
            loading ? "cursor-not-allowed" : "cursor-pointer",
            "inline-flex gap-1 text-green-primary bg-green-secondary hover:bg-green-secondary  hover:text-green-primary   p-2 rounded border-0   text-center  text-sm font-medium mt-4"
          )}
          aria-label="directions"
        >
          {loading ? (
            <>
              <p>Loading</p>
              <CircularProgress
                style={{ width: "20px", height: "20px", color: "#30716E" }}
              />
            </>
          ) : (
            <>
              <p>Submit</p>
              <SendIcon />
            </>
          )}
        </button>
      </div>
    </form>
  );
};
export default FormContact;
