import clsx from "clsx";
import styles from "./Header.module.scss";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import image from "../../assets/images/logo.svg";
import { useMediaQuery } from "react-responsive";
import { Spin as Hamburger } from "hamburger-react";
import axios from "axios";
import * as RiIcons from "react-icons/ri";

function Navbar({ setPath }: any) {
  const isMobile = useMediaQuery({
    query: "(max-width: 920px)",
  });
  const [heading, setHeading] = useState("");
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { i18n } = useTranslation();
  const lng = i18n.language === "fr" ? "fr" : "en";
  const [logo, setLogo] = useState("");
  const [item, setItem] = useState<any>([]);
  const Links = ["/", "/our-partners", "/", "/", "/", "/contact-us"];
  const childlinks = [
    [],
    [],
    ["/catalogue", "/afcfta"],
    ["/publications", "/press-releases", "/gallery", "/newsletters"],
    ["/event", "/conferences", "/workshops", "/webinars", "/masterclasses"],
    [],
  ];
  async function Apielement() {
    Promise.all([
      axios.get(
        `${process.env.REACT_APP_WORDPRESS}/header?_fields=acf.menu_${lng}`
      ),
      axios.get(`${process.env.REACT_APP_WORDPRESS}/logo_background`),
    ])
      .then(([res1, res2]) => Promise.all([res1.data, res2.data]))
      .then(([items, logo]) => {
        setLogo(logo?.[0]?.acf?.logo);
        let tab: any[] = [];
        items.forEach((header: any, index: number) => {
          if (!header?.acf?.["menu_" + lng]?.disable && index !== 4) {
            tab.push({
              label: header?.acf?.["menu_" + lng]?.Menu_Items,
              value: Links[index],
              children: [],
            });
          } else {
            index !== 4 &&
              tab.push({
                label: "",
                value: "",
                children: [],
              });
          }
        });
        items.forEach((header: any, index: number) => {
          Object.values(header?.acf?.["menu_" + lng]?.children)?.forEach(
            (child: any, indexchld: any) => {
              if (
                !child?.disable &&
                !header?.acf?.["menu_" + lng]?.disable &&
                child?.title &&
                indexchld !== 3 &&
                index !== 4
              ) {
                tab[
                  items.findIndex((object: any) => {
                    return (
                      object?.acf?.["menu_" + lng].Menu_Items ===
                      header?.acf?.["menu_" + lng]?.Menu_Items
                    );
                  })
                ]?.children?.push({
                  label: child?.title,
                  value: childlinks[index][indexchld],
                });
              }
            }
          );
        });
        setItem(tab);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    Apielement();
  }, [i18n.language]);
  const changeLanguage = (value: any) => {
    i18n.changeLanguage(value);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <header className={styles.header} ref={wrapperRef}>
      <Link to="/" preventScrollReset={true}>
        <img
          src={logo ? logo : image}
          alt="logo"
          loading="lazy"
          width={"80"}
          className="my-3 shadow-2xl rounded-full"
        />
      </Link>
      <div className="flex flex-col gap-4 pt-4">
        <div className={"hidden min-[920px]:flex justify-end"}>
          {/* <SocialMedia
            text={t("follow")}
            color="#467281"
            className="float-right"
          /> */}
        </div>
        <div className="flex flex-row items-center">
          <nav
            className={clsx(
              styles.navbar,
              open && styles.active,
              isMobile && "no-dec"
            )}
            id="test"
          >
            <ul className={clsx(isMobile && "no-dec")}>
              {item?.map((link: any) => {
                return (
                  <>
                    <li key={link?.value} className={" xl:ml-8 lg:ml-4"}>
                      {link?.children.length === 0 ? (
                        <Link
                          preventScrollReset={true}
                          to={link?.value}
                          className={styles.a}
                          onClick={() => {
                            localStorage.setItem(
                              "path",
                              window.location.origin + link?.value
                            );
                            setPath(window.location.origin + link?.value);
                            setOpen((prev) => !prev);
                          }}
                        >
                          <p
                            className={clsx(
                              isMobile && "pl-4",
                              !isMobile &&
                                link?.value === window.location.pathname
                                ? "lg:border-green-primary text-green-primary"
                                : "text-[#666]",
                              " border-b-2 border-solid border-transparent lg:hover:border-[#30716E] lg:hover:text-green-primary "
                            )}
                          >
                            {link.label}
                          </p>
                        </Link>
                      ) : (
                        <>
                          <button
                            className="flex flex-row items-center"
                            onClick={() => {
                              heading !== link.label
                                ? setHeading(link.label)
                                : setHeading("");
                            }}
                          >
                            <p
                              className={clsx(
                                styles.a,
                                !isMobile &&
                                  link?.children.filter(
                                    (item: any) =>
                                      item?.value === window.location.pathname
                                  ).length !== 0
                                  ? "border-b-2 border-solid lg:border-green-primary text-green-primary"
                                  : "text-[#666] pl-4"
                              )}
                            >
                              {link?.label}
                            </p>
                            <RiIcons.RiArrowDownSFill color="#737980" />
                          </button>
                          {isMobile ? (
                            <div
                              className={`  ${
                                heading === link.label ? "lg:hidden" : "hidden"
                              }`}
                            >
                              {link?.children?.map((slinks: any) => {
                                return (
                                  <li key={slinks} className="py-3 pl-14">
                                    <Link
                                      to={slinks.value}
                                      onClick={() => {
                                        setOpen((prev) => !prev);
                                        setHeading("");
                                      }}
                                      className={clsx(isMobile && "pl-4")}
                                    >
                                      {slinks.label}
                                    </Link>
                                  </li>
                                );
                              })}
                            </div>
                          ) : (
                            <ul className={clsx(styles.submenu, "lg:ml-2")}>
                              {link.children?.map((child: any) => {
                                return (
                                  <li
                                    key={child.value}
                                    className="w-fit xl:w-full  m-auto px-3"
                                  >
                                    <Link
                                      to={child?.value}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "path",
                                          window.location.origin + link?.value
                                        );
                                        setOpen((prev) => !prev);
                                        setPath(
                                          window.location.origin + link?.value
                                        );
                                      }}
                                    >
                                      <div className="w-fit xl:w-full md:mt-2 flex-wrap">
                                        {child?.label}
                                      </div>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </>
                      )}
                      {isMobile && <hr className="bg-[#D4FFF1] mt-2" />}
                    </li>
                  </>
                );
              })}
              <li>
                <div
                  className="flex flex-row items-center"
                  onClick={() => {
                    heading !== i18n.language
                      ? setHeading(i18n.language)
                      : setHeading("");
                  }}
                >
                  <p
                    className={clsx(
                      styles.a,
                      styles.ml,
                      "text-[#666] text-[18px] pl-4"
                    )}
                  >
                    {i18n.language === "fr" ? "Fr (FR)" : "Eng (US)"}
                  </p>
                  <RiIcons.RiArrowDownSFill color="#737980" />
                </div>
                {isMobile ? (
                  <div
                    className={`  ${
                      heading === i18n.language ? "lg:hidden" : "hidden"
                    }`}
                  >
                    <div>
                      <div className="pl-4">
                        <li
                          className="py-3 pl-14"
                          onClick={() => {
                            changeLanguage("en");
                            setOpen((prev) => !prev);
                          }}
                        >
                          Eng (US)
                        </li>
                        <li
                          onClick={() => {
                            changeLanguage("fr");
                            setOpen((prev) => !prev);
                          }}
                        >
                          Fr (FR)
                        </li>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ul className={clsx(styles.submenu, styles.ml, "lg:w-full")}>
                    <li
                      onClick={() => {
                        changeLanguage("en");
                        setOpen((prev) => !prev);
                      }}
                      className="md:pt-2 -pl-4"
                    >
                      <a> Eng (US)</a>
                    </li>
                    <li
                      onClick={() => {
                        changeLanguage("fr");
                        setOpen((prev) => !prev);
                      }}
                      className="md:pt-2"
                    >
                      <a> Fr (FR)</a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className={styles?.["menu-icon"]} onClick={() => setHeading("")}>
        <div id="menu-btn" className="fas fa-bars items-center">
          <Hamburger toggled={open} toggle={setOpen} color="#666666" />
        </div>
      </div>
    </header>
  );
}

export default Navbar;
