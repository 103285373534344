import clsx from "clsx";

const BoxGallery = (props: any) => {
  return (
    <div className="bg-gray-100 flex flex-wrap justify-center overflow-y-scroll h-[80vh] custom-scrollbar">
      {props?.data?.map((items: any, index: number) => (
        <img
          key={items?.acf?.image}
          src={items?.acf?.image}
          alt="image conetnt"
          loading="lazy"
          className={clsx(
            index % 5 < 3 ? "w-1/3" : "w-2/5",
            "object-cover aspect-square p-1"
          )}
        />
      ))}
    </div>
  );
};

export default BoxGallery;
