import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { type TFunction } from "i18next";
import fetchdata from "../service/afcfta";
import styles from "./styles.module.scss";
import InfiniteScrollBar from "../components/loader/Loader";

export default function AfCFTA() {
  const [tab, setTab] = React.useState("0");
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const [data, setData] = React.useState<any>({});
  const [virtual_session, Setvirtual_session] = React.useState<string>();
  const [loading, setLoading] = React.useState(true);

  const fetch = async () => {
    setLoading(true);
    await fetchdata()
      .then((res: any) => {
        setData(res?.data);
        Setvirtual_session(
          res?.data?.[`afcfta_course_outline_${language}`]?.virtual_session
        );
        setLoading(false);
      })
      .catch(console.error);
  };
  useEffect(() => {
    fetch();
  }, [language]);
  const tabsContent: Record<string, React.ReactNode> = {
    "0": <Tab0 data={data?.[`overview_${language}`]} />,
    "1": <Tab1 data={data?.[`learning_objectives_${language}`]} />,
    "2": <Tab2 data={data?.[`afcfta_course_design_${language}`]} />,
    "3": (
      <Tab3
        data={data?.[`afcfta_course_outline_${language}`]}
        virtual_session={virtual_session || ""}
      />
    ),
    "4": <Tab4 data={data?.[`afcfta_who_should_attend_${language}`]} />,
    "5": (
      <Tab5
        data={
          data?.[`afcfta_about_the_trade_intelligence_solutions_${language}`]
        }
      />
    ),
  };
  const tabsLabel = [
    "afcfta_tab0",
    "afcfta_tab1",
    "afcfta_tab2",
    "afcfta_tab3",
    "afcfta_tab4",
    "afcfta_tab5",
  ];
  if (loading) return <InfiniteScrollBar />;
  return (
    <div className="max-w-[1440px] w-full mx-auto px-3 my-12">
      <div className="grid lg:grid-cols-3 grid-cols-2 gap-x-6 gap-y-3 my-8 items-center">
        <div className="flex gap-2 items-center">
          <LazyLoadImage
            src={data?.[`afcfta_block_1_${language}`]?.logos?.logo1}
            alt=""
            effect="blur"
            className="max-w-[clamp(150px,60vw+0.2rem,280px)]"
          />
        </div>
        <div className="lg:col-start-auto lg:col-end-auto lg:row-start-auto col-start-1 col-end-3 row-start-1 justify-self-center max-w-[clamp(150px,60vw+0.2rem,280px)]">
          <LazyLoadImage
            src={data?.[`afcfta_block_1_${language}`]?.logos?.logo2}
            alt=""
            effect="blur"
          />
        </div>
        <div className="max-w-[clamp(150px,60vw+0.2rem,280px)] min-w-[130px] lg:max-w-[320px] justify-self-end">
          <LazyLoadImage
            src={data?.[`afcfta_block_1_${language}`]?.logos?.logo3}
            alt=""
            effect="blur"
          />
        </div>
      </div>
      <div className="rounded shadow-lg mx-1 px-3 py-8">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.[`afcfta_block_1_${language}`].title,
          }}
          className="text-[#00AA8C] text-[clamp(1.5rem,7vw+0.1rem,2.5rem)] text-center my-8 font-bold w-3/4 mx-auto"
        />
        <div className="text-center">
          <a
            href={data?.[`afcfta_block_1_${language}`]?.upload_pdf}
            target="_blank"
            rel="noreferrer"
            download
            className="inline-block bg-[#26FFBA] rounded px-8 py-2 min-h-[48px] sm:px-10 sm:text-xl text-[#30716E] text-lg font-medium"
          >
            {data?.[`afcfta_block_1_${language}`]?.button_title}
          </a>
        </div>
        <div className="px-3 font-[TitilliumWebreqular] mt-12">
          <Tabs
            value={parseInt(tab, 10)}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            centered
            variant="scrollable"
            scrollButtons="auto"
            className="bg-[#D4FFF1] rounded-tl-lg rounded-tr-lg mb-6"
            sx={{
              ".MuiTabs-indicator": {
                backgroundColor: "#4CA78D !important",
              },
              ".MuiTab-root": {
                color: "#737373",
                textTransform: "none",
                fontSize: "1.125rem",
                fontFamily: "TitilliumWebreqular",
              },
              ".Mui-selected": {
                color: "#00AA8C !important",
                fontFamily: "TitilliumWebsemiblod",
              },
            }}
          >
            {tabsLabel.map((label, index) => (
              <Tab key={index + label} value={index} label={t(label)} wrapped />
            ))}
          </Tabs>
          {tabsContent[parseInt(tab, 10)]}
        </div>
      </div>
    </div>
  );
}

function Tab0({ data }: { data: any }) {
  return (
    <div className="grid min-[1060px]:grid-cols-[min(60%,440px),1fr] min-[1180px]:grid-cols-[min(40%,480px),1fr] gap-x-6 gap-y-8">
      <LazyLoadImage
        src={data?.image}
        alt=""
        effect="blur"
        className="w-full object-cover h-full"
        wrapperProps={{
          style: {
            aspectRatio: 1,
            maxWidth: "480px",
            marginInline: "auto",
            width: "100%",
            height: "100%",
          },
        }}
      />
      <div className="flex flex-col gap-2">{data?.description}</div>
    </div>
  );
}

function Tab1({ data }: { data: any }) {
  return (
    <div className="grid min-[1060px]:grid-cols-[1fr,min(60%,480px)] min-[1180px]:grid-cols-[1fr,min(40%,540px)] gap-x-6 gap-y-8">
      <LazyLoadImage
        src={data?.image}
        alt=""
        effect="blur"
        className="w-full object-cover h-full"
        wrapperProps={{
          style: {
            aspectRatio: 1,
            maxWidth: "480px",
            marginInline: "auto",
            width: "100%",
            height: "100%",
          },
        }}
      />
      <div className="min-[1060px]:-order-1">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
          className={styles.styleList}
        />
      </div>
    </div>
  );
}

function Tab2({ data }: { data: any }) {
  return (
    <div className="grid min-[1060px]:grid-cols-[min(45%,480px),1fr] min-[1180px]:grid-cols-[min(60%,540px),1fr] gap-x-6 gap-y-8">
      <LazyLoadImage
        src={data?.image}
        alt=""
        effect="blur"
        className="w-full object-cover h-full object-img-center"
        wrapperProps={{
          style: {
            aspectRatio: 2,
            maxWidth: "580px",
            marginInline: "auto",
            width: "100%",
            height: "100%",
          },
        }}
      />
      <div className="flex flex-col gap-2">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
        />
      </div>
    </div>
  );
}

function Tab3({
  data,
  virtual_session,
}: {
  data: any;
  virtual_session: string;
}) {
  const { t } = useTranslation();
  delete data.virtual_session;
  return (
    <div className="grid md:grid-cols-2 gap-6">
      {Object?.values(data)?.map((el: any, index: number) => {
        const tab = Array.from(
          {
            length: Object?.values(el)?.length - 1 / 2,
          },
          (_, indice) => {
            return {
              module: el?.[`module_${index + 1 + indice}`],
              description: el?.[`description_module_${index + 1 + indice}`],
            };
          }
        );
        return (
          <div key={index} className="flex flex-col gap-2">
            <h2 className="font-[Titilliumbold] text-[#007772] text-2xl">
              {t(el.title)}
            </h2>

            <div className="flex flex-col gap-5">
              {tab?.map((item: any, i: number) => {
                return (
                  <div key={i + item?.module}>
                    <h3 className="text-[#00AA8C] font-[TitilliumWebsemiblod] text-lg mb-2">
                      {item?.module}
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                      className={styles.styleListCourse}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div></div>
      <div className="rounded-lg border-2 w-fit bg-[#4CA78D]/20 border-green-primary py-4 px-3 mt-4 md:mt-auto ml-auto ">
        <p className="text-[#636363] mb-2">{virtual_session}</p>
      </div>
    </div>
  );
}

function Tab4({ data }: { data: any }) {
  return (
    <div className="flex flex-col gap-2 max-w-[720px]">
      <div
        dangerouslySetInnerHTML={{
          __html: data?.description,
        }}
        className={styles.styleList}
      />
    </div>
  );
}

function Tab5({ data }: { data: any }) {
  return (
    <div className="grid gap-y-8 min-[860px]:grid-cols-2">
      <div className="flex flex-col gap-2">
        <h2 className="font-[Titilliumbold]">{data?.title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
        />
      </div>
      <a
        href={data?.upload_pdf}
        target="_blank"
        rel="noreferrer"
        download
        className="block font-[TitilliumWebsemiblod] text-xl px-8 py-2.5 text-center text-green-primary bg-green-secondary uppercase place-self-end rounded lg:w-2/3"
      >
        {data?.button_title}
      </a>
    </div>
  );
}
