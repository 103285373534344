import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./styles.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { MdOutlineClear } from "react-icons/md";
const SearchInput = ({
  search,
  handleChange,
  handleSearch,
  className,
  isMedia,
  setSearch,
}: any) => {
  const onChange = (event: any) => {
    handleChange(event);
  };
  const onClick = () => handleSearch();
  const { t } = useTranslation();
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      onClick();
    }
  };
  return (
    <div className={clsx(className, "w-full")}>
      <TextField
        placeholder={t("search")}
        className={styles.field}
        onChange={onChange}
        value={search}
        onKeyPress={handleKeyPress}
        sx={{
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#30716E",
            },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <div className="flex flex-row gap-4 items-center">
              <InputAdornment position="end" className="cursor-pointer">
                <MdOutlineClear
                  onClick={() => {
                    setSearch("");
                    handleSearch(true);
                  }}
                />
              </InputAdornment>
              <button
                className="text-green-primary bg-green-secondary hover:bg-green-secondary  hover:text-green-primary hover:cursor-pointer  px-5 py-[5px] border-0 text-center  text-sm font-medium"
                aria-label="directions"
                onClick={(e: any) => {
                  onClick();
                  e.preventDefault();
                }}
              >
                {t("search")}
              </button>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default SearchInput;
