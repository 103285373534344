import img from "../../assets/images/img.svg";

const Footer = (props: any) => {
  return (
    <div className="md:grid flex flex-row   mx-auto grid-cols-2 gap-4 p-4 md:p-8 rounded-lg shadow md:w-2/3 w-full bg-white ">
      <div>
        <h6 className="text-[20px] text-[#00736E] font-medium leading-6">
          {props?.title}
        </h6>
        <a
          href={"mailto:" + props?.email}
          className="font-medium text-[#636363] text-[24px] mt-2"
        >
          {props?.email}
        </a>
      </div>
      <img
        src={img}
        alt="image contact"
        loading="lazy"
        className="w-4/5  hidden lg:block"
      />
    </div>
  );
};

export default Footer;
