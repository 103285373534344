import React, { useRef, useState } from "react";
import { ReactComponent as Iconplay } from "../../../assets/images/iconplay.svg";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa6";
import "./styles.scss";
const videoCard = ({ content }: any) => {
  return (
    <div
      className="relative video-card"
      style={{
        background: `linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.64) 25.52%,
          rgba(0, 0, 0, 0.11) 38.54%,
          rgba(0, 0, 0, 0) 100%
        ),
        url(${content?.image_cover})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="text">
        <p className="">{content?.name}</p>
        <p className="">{content?.tranee}</p>
        <div className="stars">
          {Array.from(Array(5).keys())?.map((e: any) =>
            e < content?.rating ? (
              <FaStar fill="white" key={e} size={"1rem"} />
            ) : (
              <CiStar fill="white" key={e} size={"1rem"} />
            )
          )}
        </div>
      </div>
      <Iconplay className="icon" />
    </div>
  );
};
export default videoCard;
