import clsx from "clsx";

const Box = ({ event, className = "" }: any) => {
  return (
    <div className={clsx(className, "group")}>
      <div className="h-80 flex flex-col justify-center bg-white bg-opacity-50 shadow border border-zinc-400 border-opacity-25">
        <img
          src={event?.logo}
          alt="image content"
          loading="lazy"
          className={clsx(
            "lg:w-full lg:group-hover:w-2/3 transition-all ease-in-out duration-700 mx-auto"
          )}
        />
        <div className="px-4 text-center space-y-2 relative lg:hidden lg:group-hover:block lg:-bottom-full group-hover:top-0 transition-all ease-in-out duration-700">
          <h3 className="text-teal-700 text-sm font-bold">{event?.name}</h3>
          <p className="font max-h-40 px-4 text-zinc-600 text-xs font-normal leading-5 text-justify">
            {event?.descriptions}
          </p>
        </div>
      </div>
      <h3 className="hidden lg:block text-teal-700 text-center text-sm font-bold mt-2">
        {event?.name}
      </h3>
    </div>
  );
};

export default Box;
