import axios from "axios";

async function fetchdata(
  language: string,
  setItem: any,
  setHero: any,
  setLoading: any
) {
  setLoading(true);
  Promise.all([
    axios.get(
      `https://cms.afracad.com/wp-json/wp/v2/partner?_fields=acf.partner_${language}&per_page=100`
    ),
    axios.get(`${process.env.REACT_APP_WORDPRESS}/hero-partener`),
  ])
    .then(([res1, res2]) => Promise.all([res1.data, res2.data]))
    .then(([items, items_hero]) => {
      let tab: any | never[] = [];

      items?.map((item: any, index: number) =>
        tab.push({ ...item?.acf?.["partner_" + language], indice: index })
      );
      setItem(tab);
      setHero(items_hero[0]?.acf);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
}

export default fetchdata;
