import { useState, useEffect } from "react";
import logo from "../../../assets/images/heropress.png";
import image from "../../../assets/images/imagepressrelease.svg";
import { SelectChangeEvent } from "@mui/material";
import axios from "axios";
import Card from "../../../sections/media_publications/press/card";
import SearchMedia from "../../../components/searchMedia/searchMedia";
import { useTranslation } from "react-i18next";
import Tags from "../../../components/tags/Tags";
import Archive from "../../../components/achive/Archive";
import RelatedArticle from "../../../components/RelatedArticle/RelatedArticle";

import InfiniteScrollBar from "../../../components/loader/Loader";
import Past from "../../../components/iconpaginate/Past";
import Next from "../../../components/iconpaginate/Next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  changecategory,
  fetchdata,
  filter,
  handlerChange,
} from "../../../service/press";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import Lottie from "lottie-react";
import nodata from "../../../assets/images/nodata.json";

const PressReleases = () => {
  const [item, setItem] = useState<any>({});
  const [random, setRandom] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [category, setCategory] = useState("");
  const [categorys, setCategorys] = useState<any>([]);
  const [years, setYears] = useState("");
  const [search, setSearch] = useState("");
  const [archive, setArchive] = useState("");
  const [searchtags, setSearchTags] = useState<any>();
  const [nbpage, setNbPage] = useState(0);
  const [hero, setHero] = useState<any>({});
  const handleChange = (event: SelectChangeEvent, isYear = false) => {
    handlerChange(
      event,
      isYear,
      language,
      category,
      search,
      setSearchTags,
      setArchive,
      setYear,
      setItem,
      setSearch
    );
  };
  const handleChangeCategory = (event: SelectChangeEvent) => {
    changecategory(
      event,
      language,
      year,
      search,
      setSearchTags,
      setArchive,
      setCategory,
      setItem
    );
  };
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    fetchdata(
      language,
      nbpage,
      setLoading,
      setItem,
      setCategorys,
      setRandom,
      setYears,
      setHero
    );
  }, [language]);
  const handelfilter = async (etat: boolean) => {
    filter(
      etat,
      language,
      category,
      year,
      search,
      setSearchTags,
      setArchive,
      setItem
    );
  };

  const pagination = async (etat: boolean) => {
    setNbPage(() => (etat ? nbpage + 1 : nbpage - 1));
    axios
      .post(`${process.env.REACT_APP_NODE}/press/filter`, {
        page: etat ? nbpage + 1 : nbpage - 1,
        lang: language,
        category: category,
        year: year,
      })
      .then((res) => setItem(res.data))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      {loading ? (
        <InfiniteScrollBar />
      ) : (
        <>
          <Helmet>
            {/* <title>Afracad | catalogue</title> */}
            <meta name="title" content="Afracad | publications" />
            <meta
              name="description"
              content={hero?.description
                .replace(/\n/g, " ")
                .replace(/<[^>]+>/g, "")
                .replace(/\s{2,}/g, " ")
                .trim()}
            />
          </Helmet>
          <section className="bg-green-primary lg:bg-transparent relative ">
            <img
              src={logo}
              alt="hero image"
              loading="lazy"
              className="hidden lg:block w-full lg:absolute top-0 -z-10 h-full"
            />
            <div className="container mx-auto flex flex-row items-center justify-center h-full lg:pb-32 p-6">
              <div className="flex flex-col gap-6">
                <h1 className="text-green-secondary text-2xl font-normal uppercase tracking-wide">
                  {t("pressreleases")}
                </h1>
                <div
                  className="textfonttitlepress "
                  dangerouslySetInnerHTML={{
                    __html: hero?.title,
                  }}
                />
                <div
                  className="textfonttitledesc "
                  dangerouslySetInnerHTML={{
                    __html: hero?.description,
                  }}
                />
              </div>
              <img
                src={image}
                alt="image press"
                loading="lazy"
                className="hidden lg:block"
              />
            </div>
          </section>

          <section className="section-paddings">
            <SearchMedia
              category={category}
              handleChangeCategory={handleChangeCategory}
              categorys={categorys}
              year={year}
              years={years}
              search={search}
              filter={handelfilter}
              handleChange={handleChange}
              setSearch={setSearch}
            />
          </section>
          <section>
            <div className="container flex flex-row justify-end gap-3">
              <Past
                etat={Math.ceil(item?.count / 5) - 1 >= nbpage && nbpage > 0}
                pagination={pagination}
                isPast={true}
              />
              <Next
                etat={Math.ceil(item?.count / 5) - 1 > nbpage}
                pagination={pagination}
              />
            </div>
          </section>
          <section className="section-paddings ">
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 grid-flow-row break-words gap-8">
              <div className="lg:col-span-2 row-span-5 flex flex-col gap-6 h-full">
                {item?.result?.length ? (
                  item?.result?.map((item: any, index: number) => (
                    <Card item={item} index={index} key={index} />
                  ))
                ) : (
                  <Lottie
                    animationData={nodata}
                    loop={true}
                    style={{ height: 400 }}
                    className="col-span-2"
                  />
                )}
                <br />
                {item?.count !== 0 && (
                  <div className="border-solid border-t-2 border-[#B3B3B3] flex flex-row justify-between pt-4">
                    <button
                      className={clsx(
                        Math.ceil(item?.count / 5) - 1 >= nbpage && nbpage > 0
                          ? " text-[#006666] cursor-pointer"
                          : "text-gray-500 cursor-default",
                        "flex flex-row gap-2 items-center"
                      )}
                      onClick={() =>
                        Math.ceil(item?.count / 5) - 1 >= nbpage &&
                        nbpage > 0 &&
                        pagination(false)
                      }
                    >
                      <ChevronLeftIcon fontSize="small" />
                      <span>{t("previous")}</span>
                    </button>
                    <p className="text-[#006666]">
                      Page {nbpage + 1}/{Math.ceil(item?.count / 5)}
                    </p>
                    <button
                      className={clsx(
                        Math.ceil(item?.count / 5) - 1 > nbpage
                          ? "text-[#006666] cursor-pointer"
                          : "text-gray-500 cursor-default",
                        "flex flex-row gap-2 items-center "
                      )}
                      onClick={() =>
                        Math.ceil(item?.count / 5) - 1 > nbpage &&
                        pagination(true)
                      }
                    >
                      <span> {t("next")}</span>
                      <NavigateNextIcon fontSize="small" />
                    </button>
                  </div>
                )}
              </div>

              <Tags
                setData={setItem}
                url={`${process.env.REACT_APP_NODE}/press/filter`}
                searchtags={searchtags}
                setSearchTags={setSearchTags}
                setCategory={setCategory}
                category={category}
                setYear={setYear}
                year={year}
                setSearch={setSearch}
                setNbPage={setNbPage}
                setArchive={setArchive}
              />
              <Archive
                setData={setItem}
                url={`${process.env.REACT_APP_NODE}/press/filter`}
                setSearchTags={setSearchTags}
                searchtags={searchtags}
                setSearch={setSearch}
                setCategory={setCategory}
                setYear={setYear}
                setNbPage={setNbPage}
                setArchive={setArchive}
                archive={archive}
              />
              <div className="h-fit border-[#00736E] border-solid border-2 p-4 flex flex-col gap-4">
                <h6 className="text-center text-teal-700 text-xl font-medium uppercase">
                  {t("relatedarticle")}
                </h6>
                <RelatedArticle random={random} path="/press-releases/" />
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default PressReleases;
