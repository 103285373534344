import { useState, useEffect } from "react";
import clsx from "clsx";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer";
import ContentCard from "../../../components/contentCard/ContentCard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/bg-catalogue.svg";
import axios from "axios";
import InfiniteScrollBar from "../../../components/loader/Loader";
import { MdArrowBackIos } from "react-icons/md";
import SocialMediaShare from "../../../components/socialMedia/SocialMediaShare";
import { fetchdatadetails } from "../../../service/gallery";
import Hero from "../../../components/hero/hero";

function Gallery() {
  const { id } = useParams();
  const [content, setContent] = useState<any>();
  const [otherContent, setOtherContent] = useState<any>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    fetchdatadetails(
      language,
      id || "",
      setLoading,
      setContent,
      setOtherContent,
      navigate
    );
  }, [language, id]);
  return (
    <div>
      {loading ? (
        <InfiniteScrollBar />
      ) : (
        <div className="flex flex-col gap-10">
          <section className="hidden lg:block relative">
            <div className="w-full mx-auto absolute top-0 -z-10">
              <Hero hero={""} />
            </div>
            <img
              src={content?.hero_gallery}
              alt="img gallery"
              loading="lazy"
              className="object-cover  h-[560px] rounded-lg w-5/6 mx-auto pt-[94px]"
            />
          </section>
          <section className="section-paddings relative mx-auto h-full overflow-visible pt-0 block lg:hidden">
            <div className="container mx-auto">
              <img
                src={content?.hero_gallery}
                alt="img gallery"
                loading="lazy"
                className="object-cover lg:h-[560px] relative w-full rounded-lg"
              />
            </div>
          </section>
          <section className="section-paddings">
            <div className="container lg:grid lg:grid-cols-2 justify-center items-start gap-8">
              <div className="flex flex-row justify-between col-span-2">
                <SocialMediaShare color="#066" />
                <Link
                  to="/gallery"
                  className="flex flex-row justify-center items-center bg-transparent border-transparent cursor-pointer"
                >
                  <MdArrowBackIos color="#737980" />
                  <p className="text-[#737980] text-xl font-medium">
                    {t("back")}
                  </p>
                </Link>
              </div>
              <div className="py-4 flex flex-col gap-4">
                <div className="inline-flex gap-4 text-emerald-500 ">
                  <hr className="w-14 h-0 border-emerald-500 my-auto" />
                  {content?.company}
                </div>
                <h1 className="text-3xl text-teal-700 font-bold leading-10 tracking-tight">
                  {content?.title}
                </h1>
                <p className="text-yellow-400 text-2xl font-bold leading-7 tracking-tight">
                  {new Date(content?.date).toLocaleDateString([language], {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
                <div>
                  <p className="font text-justify text-neutral-600 text-base font-normal leading-tight tracking-tight">
                    {content?.description.split("\r\n")?.[0]}
                  </p>
                  <p className="font text-justify text-neutral-600 text-base font-normal leading-tight tracking-tight">
                    {content?.description.split("\r\n")?.[1]}
                  </p>
                </div>
              </div>
              <img
                src={content.image_description}
                alt="img description"
                className="object-cover lg:h-[400px] w-full py-4"
                loading="lazy"
              />
              {content?.images?.map(
                (value: string, index: number) =>
                  value && (
                    <img
                      key={index}
                      src={value}
                      alt={"image contet"}
                      className={clsx(
                        "object-cover lg:h-[400px] w-full py-4",
                        index === 0 && "col-span-2"
                      )}
                      loading="lazy"
                    />
                  )
              )}
            </div>
          </section>
          <section>
            <div className="container mx-auto flex flex-col lg:grid lg:grid-cols-2 justify-center items-start gap-8">
              <div className="inline-flex gap-4 text-emerald-500 col-span-2">
                <hr className="w-14 h-0 border-emerald-500 my-auto" />
                Videos
              </div>
              {content?.videos?.map(
                (value: string, index: number) =>
                  value && <VideoPlayer key={index} embedId={value} />
              )}
            </div>
          </section>
          <section className="section-paddings">
            <div className="container mx-auto flex flex-col lg:grid lg:grid-cols-2 justify-center items-start gap-8">
              <div className="text-center text-slate-900 text-5xl font-bold tracking-tight col-span-2">
                {t("othergallery")}
              </div>
              {otherContent?.map(
                (content: any) =>
                  content && <ContentCard key={content.id} content={content} />
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Gallery;
