const Past = ({ etat, pagination, isPast }: any) => {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        etat && isPast && pagination(false);
        etat && !isPast && pagination(true);
      }}
      className="cursor-pointer"
    >
      <path
        d="M25.3477 27.9789C25.1016 28.2523 25.1016 28.6625 25.3477 28.9086L29.0664 32.6273C29.3398 32.9008 29.75 32.9008 29.9961 32.6273L30.625 32.0258C30.8711 31.7523 30.8711 31.3422 30.625 31.0961L27.9727 28.4437L30.625 25.8187C30.8711 25.5726 30.8711 25.1351 30.625 24.889L29.9961 24.2601C29.75 24.014 29.3398 24.014 29.0664 24.2601L25.3477 27.9789Z"
        fill={etat ? "#30716E" : "#B3CFD0"}
      />
      <rect
        x="0.5"
        y="1.19373"
        width="55"
        height="55"
        rx="27.5"
        stroke={etat ? "#30716E" : "#B3CFD0"}
      />
    </svg>
  );
};
export default Past;
