import { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import style from "./styles.module.scss";
import Box from "../sections/ourpartner/box";
import { useTranslation } from "react-i18next";
import InfiniteScrollBar from "../components/loader/Loader";
import fetchdata from "../service/ourpartners";
import clsx from "clsx";
import "react-lazy-load-image-component/src/effects/blur.css";
import Hero from "../components/hero/hero";

function OurPartners() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [item, setItem] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [hero, setHero] = useState<any>();
  useEffect(() => {
    fetchdata(language, setItem, setHero, setLoading);
  }, [language]);
  if (loading) return <InfiniteScrollBar />;
  return (
    <div className="relative flex flex-col gap-2 lg:gap-5 mb-10">
      <section className="hidden lg:block relative">
        <Hero hero={"ourpartner"}>
          <foreignObject
            x="121.12"
            y="114.2"
            width="100%"
            height="170"
            className="overflow-hidden"
          >
            <h1
              style={{ color: hero?.color_text_1 }}
              className="text-lg font-extrabold text-[#26FFBA]"
            >
              {hero?.["hero_" + language]?.titre1}
            </h1>
          </foreignObject>
          <foreignObject
            x="121.12"
            y="180.96"
            width="50%"
            height="170"
            className="overflow-hidden w-full md:w-1/2"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: hero?.["hero_" + language]?.titre2,
              }}
            />
          </foreignObject>
          <foreignObject
            x="121.12"
            y="346"
            width="100%"
            height="170"
            className="overflow-hidden"
          ></foreignObject>
        </Hero>
      </section>
      <section className="reltive block lg:hidden">
        <div className="bg-green-primary   relative flex flex-row overflow-visible h-fit">
          <div
            className={clsx(
              style.ourpartner,
              "section-paddings container mx-auto  flex flex-col gap-5 h-full z-10"
            )}
          >
            <h1
              style={{ color: hero?.color_text_1 }}
              className="w-full lg:w-1/2 text-xl lg:text-2xl font-bold uppercase text-green-secondary "
            >
              {hero?.["hero_" + language]?.titre1}
            </h1>
            <div
              className={
                (style.textfont, "textfonttitle w-full lg:w-1/2 uppercase")
              }
              dangerouslySetInnerHTML={{
                __html: hero?.["hero_" + language]?.titre2,
              }}
            />
          </div>
        </div>
      </section>
      <section className={style.bgOurPartner}>
        <div className="section-paddings relative ">
          <div className="container">
            <p className=" text-xl md:text-4xl font-bold mx-auto">
              {t("subTitleOurPartner")}
            </p>
            <Marquee className="section-paddings">
              {item?.map((e: any) => {
                return (
                  <img
                    src={e?.logo}
                    alt="logo ourpartner"
                    loading="lazy"
                    className="h-36"
                    width={"100%"}
                    key={e?.logo}
                  />
                );
              })}
            </Marquee>
          </div>

          <div className="container flex flex-wrap justify-center gap-6 mx-auto w-full">
            {item?.map(
              (event: any) =>
                event.logo && (
                  <Box
                    className="lg:basis-1/4"
                    event={event}
                    key={event.logo}
                  />
                )
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurPartners;
