import clsx from "clsx";
import style from "./styles.module.scss";

const BoxEnablers = ({
  data,
}: {
  data: {
    icon?: string;
    title?: string;
    description?: string;
    color?: string;
  };
}) => {
  return (
    <div className="p-5 bg-white rounded-xl border border-black border-opacity-5 flex-col justify-start items-center gap-5 inline-flex">
      <img src={data?.icon} alt="image content" loading="lazy" />
      <p
        className="fontbold text-center text-lg font-black uppercase"
        style={{ color: data?.color }}
      >
        {data?.title}
      </p>
      <p
        className={clsx(
          style.fonttext,
          " text-cente text-base font-normal leading-normal tracking-wide text-justify"
        )}
      >
        {data?.description}
      </p>
    </div>
  );
};

export default BoxEnablers;
