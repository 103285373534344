import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Button from "../../../components/button/Button";
const Card = (props: any) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <div
      className={clsx(
        props.index === 0 && "col-span-2",
        "flex flex-col gap-1 shadow rounded p-4 justify-between"
      )}
    >
      <p className="text-[#333D3E] text-xs font-normal leading-tight">Date :</p>
      <p className="text-[#698287] text-base font-medium">
        {new Date(props.item?.date).toLocaleDateString([language], {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: props.item?.title,
        }}
        className={styles.cont}
      />
      <div className={clsx(styles.desc, "font line-clamp-3")}>
        {props.item?.description}
      </div>

      <div className="w-fit">
        <Link to={"/newsletters/" + props.item?.id}>
          <Button density={"dense"} className="mt-4 whitespace-nowrap">
            {t("readmore")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Card;
