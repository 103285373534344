import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from "./assets/locales/en/translation.json";
import frLocale from "./assets/locales/fr/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { GetLanguage } from "./helper/GetLanguage.helper";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: { translation: enLocale },
      fr: { translation: frLocale },
    },
    lng: GetLanguage(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
