import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import clsx from "clsx";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Carousel = ({ content, containerClassName, isBig = false }: any) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  return (
    <div
      className={clsx(
        "card",
        showMore && "is-shown",
        containerClassName,
        isBig && "is-big"
      )}
      style={{
        background: `url(${content?.image}) fixed no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div className="overlay">
        <div className="first-side">
          <span className="title">{content?.title}</span>
          <button
            className="flip-btn"
            onClick={(e) => {
              setShowMore(true);
              e.currentTarget.blur();
            }}
          >
            {t("readmore")}
          </button>
        </div>
        <div className="second-side">
          <button className="close" onClick={() => setShowMore(false)}>
            <IoIosClose className="w-8 h-8" />
          </button>
          <span className={clsx("desc", isBig ? "xl:text-lg" : "text-base")}>
            {content?.description}
          </span>
        </div>
      </div>
    </div>
  );
};
export default Carousel;
