import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import defultimg from "../../assets/images/default2.svg";
const RelatedArticle = ({ random, path }: any) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <div className="divide-y divide-solid">
      {Array.isArray(random) &&
        random?.map((article: any, index: any) => (
          <div className="py-2" key={article?._id}>
            <Link to={path + article?.id}>
              <div className="grid grid-cols-2 gap-4">
                {article.image && (
                  <img
                    src={article.image !== "false" ? article.image : defultimg}
                    alt="image article"
                    loading="lazy"
                    className="w-full aspect-video"
                  />
                )}
                <div className="flex flex-col gap-1">
                  <p className="text-emerald-500 text-sm font-medium capitalize">
                    {article?.date &&
                      new Date(article.date).toLocaleDateString([language], {
                        month: "long",
                        year: "numeric",
                      })}
                  </p>
                  <h6 className="line-clamp-3 text-teal-700 text-sm font-bold">
                    {article.title}
                  </h6>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default RelatedArticle;
