import axios from "axios";

async function fetchdata(language: string) {
  let res = {};
  await Promise.all([
    axios.get(
      `${process.env.REACT_APP_WORDPRESS}/hero_catalogue?_fields=acf.hero_catalogue_${language}`
    ),
    axios.get(
      `${process.env.REACT_APP_WORDPRESS}/Statement?_fields=acf.statement_${language}`
    ),
    axios.get(
      `${process.env.REACT_APP_WORDPRESS}/gallery_catalogue?per_page=10000`
    ),
    axios.get(`${process.env.REACT_APP_WORDPRESS}/experience?per_page=10000`),
    axios.get(`${process.env.REACT_APP_WORDPRESS}/catalogue`),
    axios.get(
      `${process.env.REACT_APP_WORDPRESS}/Learning_Solutions?per_page=10000`
    ),
  ])
    .then(([res1, res2, res3, res4, res5, res6]) =>
      Promise.all([
        res1.data,
        res2.data,
        res3.data,
        res4.data,
        res5.data,
        res6.data,
      ])
    )
    .then(([data1, data2, data3, data4, data5, data6]) => {
      res = {
        hero: data1[0]?.acf?.["hero_catalogue_" + language],
        statement: data2[0]?.acf?.["statement_" + language],
        catalogueGallery: data3,
        catalogueVideo: data4,
        data: data5[0]?.acf?.["catalogue_static_content_" + language],
        images: data6,
      };
    })
    .catch((error) => {
      console.error({ error });
    });
  return res;
}
export default fetchdata;
