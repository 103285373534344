import { useState, useEffect } from "react";
import ContentCard from "../../../components/contentCard/ContentCard";
import Button from "../../../components/button/Button";
import logo from "./bg-green.svg";

import clsx from "clsx";
import { Link } from "react-router-dom";
import styles from "../styles.module.scss";
import SearchInput from "../../../components/search/Search";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";
import { MdOutlineClear } from "react-icons/md";
import styled from "./styles.module.scss";
import InfiniteScrollBar from "../../../components/loader/Loader";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { fechdata, handlerSearch } from "../../../service/gallery";
import Lottie from "lottie-react";
import nodata from "../../../assets/images/nodata.json";

function Gallery() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [lastContent, setLastContent] = useState<any>({});
  const [contents, setContents] = useState<any>([]);
  const [displayCount, setDisplayCount] = useState(2);
  const [displayContents, setDisplayContents] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [search, setSearch] = useState("");
  const [years, setYears] = useState<any>({});
  const [nbpage, setNbPage] = useState(0);
  useEffect(() => {
    setDisplayContents(contents);
  }, [displayCount, contents]);
  async function handleSearch(etat: boolean) {
    handlerSearch(etat, language, year, search, setContents);
  }

  useEffect(() => {
    fechdata(
      loading,
      language,
      setLoading,
      setContents,
      setYears,
      setLastContent
    );
  }, [language]);

  const handleChange = (event: SelectChangeEvent, isYear = false) =>
    isYear ? setYear(event.target.value) : setSearch(event.target.value);

  const pagination = async () => {
    setNbPage(() => nbpage + 1);
    axios
      .post(`${process.env.REACT_APP_NODE}/gallery/filter`, {
        page: nbpage + 1,
        lang: language,
      })
      .then((res) =>
        setContents((test: any) => {
          const gallery = {
            count: test?.count,
            result: [...test?.result, ...res.data?.result],
          };
          return gallery;
        })
      )
      .catch((err) => console.log(err));
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
  };

  const slider = React.useRef<Slider>(null);
  const next = () => slider?.current && slider?.current.slickNext();
  const previous = () => slider?.current && slider?.current.slickPrev();
  return (
    <div>
      {loading ? (
        <InfiniteScrollBar />
      ) : (
        <div className="flex flex-col gap-8">
          <section>
            <div className=" bg-green-primary lg:bg-transparent relative flex flex-row overflow-visible h-fit">
              <img
                src={logo}
                alt="hero image"
                loading="lazy"
                className="hidden lg:block w-full"
              />
              <div className="section-paddings container mx-auto lg:absolute flex flex-col gap-5 h-full z-10 lg:mt-10">
                <div className="flex flex-col gap-8 h-full mt-[5%]  lg:w-1/2">
                  <p className="text-green-secondary text-xl  xl:text-2xl font-normal uppercase tracking-wide">
                    {t("titleGallery")}
                  </p>
                  <h1 className="text-white text-2xl xl:text-4xl font-bold capitalize leading-[60px]">
                    <span className="text-green-secondary">
                      {lastContent?.company + ": "}
                    </span>
                    {lastContent?.title}
                  </h1>
                  <Link className="w-fit" to={"/gallery/" + lastContent?.id}>
                    <Button density={"dense"}>{t("discover")}</Button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className={clsx(
                "container flex flex-row justify-center items-center"
              )}
            >
              <svg
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  previous();
                }}
                className="cursor-pointer hidden lg:block"
              >
                <path
                  d="M25.3477 27.9789C25.1016 28.2523 25.1016 28.6625 25.3477 28.9086L29.0664 32.6273C29.3398 32.9008 29.75 32.9008 29.9961 32.6273L30.625 32.0258C30.8711 31.7523 30.8711 31.3422 30.625 31.0961L27.9727 28.4437L30.625 25.8187C30.8711 25.5726 30.8711 25.1351 30.625 24.889L29.9961 24.2601C29.75 24.014 29.3398 24.014 29.0664 24.2601L25.3477 27.9789Z"
                  fill={isMobile ? "#ffffff" : "#30716E"}
                />
                <rect
                  x="0.5"
                  y="1.19373"
                  width="55"
                  height="55"
                  rx="27.5"
                  stroke={isMobile ? "#ffffff" : "#30716E"}
                />
              </svg>
              <div className="w-full lg:w-5/6 m-8 ">
                <Slider {...settings} ref={slider} className="h-full">
                  {lastContent?.images?.map((value: any, index: any) => (
                    <img
                      key={index}
                      src={value}
                      alt={"image slider"}
                      loading="lazy"
                      className="object-cover w-full lg:w-[400px] lg:h-[400px] aspect-video  lg:aspect-auto"
                    />
                  ))}
                </Slider>
              </div>
              <svg
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none "
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => next()}
                className="cursor-pointer  hidden lg:block"
              >
                <path
                  d="M30.625 28.9086C30.7344 28.7992 30.8164 28.6351 30.8164 28.4437C30.8164 28.2797 30.7344 28.1156 30.625 27.9789L26.9062 24.2601C26.7695 24.1508 26.6055 24.0687 26.4414 24.0687C26.25 24.0687 26.0859 24.1508 25.9766 24.2601L25.375 24.889C25.2383 25.0258 25.1836 25.1898 25.1836 25.3539C25.1836 25.5453 25.2383 25.7094 25.375 25.8187L28 28.4437L25.375 31.0687C25.2383 31.2054 25.1836 31.3695 25.1836 31.5336C25.1836 31.725 25.2383 31.889 25.375 31.9984L25.9766 32.6273C26.0859 32.764 26.25 32.8187 26.4414 32.8187C26.6055 32.8187 26.7695 32.764 26.9062 32.6273L30.625 28.9086Z"
                  fill={isMobile ? "#ffffff" : "#30716E"}
                />
                <rect
                  x="0.5"
                  y="1.19373"
                  width="55"
                  height="55"
                  rx="27.5"
                  stroke={isMobile ? "#ffffff" : "#30716E"}
                />
              </svg>
            </div>
          </section>
          <section className="section-paddings relative">
            <div className="container mx-auto flex flex-col grid-flow-row lg:grid lg:grid-cols-2 justify-center items-start gap-8">
              <h5 className="text-teal-700 text-[40px] font-bold leading-[60px] tracking-tight">
                {t("titlegallery")}
              </h5>
              <div className="lg:col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="w-full ">
                  <p className="text-zinc-500 text-sm font-normal mb-1">
                    {t("year")}
                  </p>
                  <FormControl fullWidth className={styled.custom_box}>
                    <Select
                      sx={{
                        fontFamily: "TitilliumWebsemiblod",
                        color: "#C2C2C2",
                        fontStyle: "normal",
                        fontWeight: "400",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#30716E",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#30716E !important",
                        },
                        "& .MuiSelect-iconOutlined": {
                          display: year ? "none" : "",
                        },
                        "&.Mui-focused .MuiIconButton-root": {
                          color: "primary.main",
                        },
                      }}
                      displayEmpty
                      value={year}
                      onChange={async (event) => {
                        handleChange(event, true);
                        await axios
                          .post(
                            `${process.env.REACT_APP_NODE}/gallery/filter`,
                            {
                              language,
                              year: event.target.value,
                              search: search,
                            }
                          )
                          .then((response) => {
                            setContents(response.data);
                          })
                          .catch((error) => console.log(error));
                      }}
                      endAdornment={
                        year && (
                          <InputAdornment
                            position="end"
                            className="cursor-pointer"
                          >
                            <MdOutlineClear
                              onClick={async () => {
                                setYear("");
                                await axios
                                  .post(
                                    `${process.env.REACT_APP_NODE}/gallery/filter`,
                                    {
                                      language,
                                      year: "",
                                      search: search,
                                    }
                                  )
                                  .then((response) => {
                                    setContents(response.data);
                                  })
                                  .catch((error) => console.log(error));
                              }}
                            />
                          </InputAdornment>
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        <em>{t("year")}</em>
                      </MenuItem>
                      {years
                        ?.sort(
                          (item1: any, item2: any) =>
                            Number(item2?.title) - Number(item1?.title)
                        )
                        ?.map((year: any) => (
                          <MenuItem key={year?.title} value={year?.title}>
                            {year?.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={clsx(
                    "lg:col-span-2  mt-[24px] w-full",
                    styled.custom_box
                  )}
                >
                  <SearchInput
                    search={search}
                    handleSearch={handleSearch}
                    handleChange={handleChange}
                    setSearch={setSearch}
                  />
                </div>
              </div>
              {displayContents?.result?.length > 0 ? (
                displayContents?.result?.map(
                  (content: any, index: any) =>
                    content && (
                      <div
                        className={clsx(
                          "relative w-full h-full aspect-video",
                          index === 0 &&
                            displayContents.length >= 4 &&
                            "lg:row-span-3"
                        )}
                        key={index}
                      >
                        <ContentCard
                          long={index === 0 && displayContents.length >= 4}
                          content={content}
                          className={index === 0 && "lg:row-span-3 h-full long"}
                        />
                      </div>
                    )
                )
              ) : (
                <Lottie
                  animationData={nodata}
                  loop={true}
                  style={{ height: 400 }}
                  className="col-span-2"
                />
              )}
            </div>
          </section>
          <section>
            <div className="container mx-auto flex justify-center section-paddings no-tp">
              {Math.ceil(contents?.count / 5) - 1 > nbpage && (
                <Button
                  onClick={(e: any) => {
                    e.preventDefault();
                    Math.ceil(contents?.count / 5) - 1 > nbpage && pagination();
                  }}
                  className="mx-auto"
                >
                  {t("loadmore")}
                </Button>
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Gallery;
