import { useState, useEffect } from "react";
import styles from "./SocialMedia.module.scss";
import { Facebook, Twitter, Instagram, Linkedin } from "./imgs";
import { useTranslation } from "react-i18next";
import axios from "axios";
const platformIcons: any = {
  facebook: <Facebook />,
  twitter: <Twitter />,
  instagram: <Instagram />,
  linkedin: <Linkedin />,
};
function SocialMedia(props: any) {
  const { t } = useTranslation();
  const [item, setItem] = useState<any>([
    {
      acf: {
        url: "",
        name: "linkedin",
      },
    },
    {
      acf: {
        url: "",
        name: "instagram",
      },
    },
    {
      acf: {
        url: "",
        name: "twitter",
      },
    },
    {
      acf: {
        url: "",
        name: "facebook",
      },
    },
  ]);
  const [loading, setLoading] = useState(false);
  async function Apielement() {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_WORDPRESS}/socialmedia`)
      .then((response) => {
        setItem(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    !props?.speaker && Apielement();
  }, []);
  return loading ? (
    <></>
  ) : (
    <div className="inline-flex flex-nowrap jusitfy-start items-center gap-4">
      <span className="capitalize " style={{ color: props.color }}>
        {props.text && t("follow")}
      </span>
      <div className="flex justify-center gap-4">
        {item?.map(({ acf: { url, name } }: any, index: any) => (
          <a
            href={props.speaker ? props?.[`url${name}`] : url}
            className={styles.svg}
            style={{ color: props.color }}
            key={index}
          >
            {platformIcons[name]}
          </a>
        ))}
      </div>
    </div>
  );
}

export default SocialMedia;
