import Head from "./header/Head";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../helper/scrollTop";
import "./styles.scss";
import { useState } from "react";

function Layout() {
  const [path, setPath] = useState("");

  return (
    <div className="flex flex-col min-h-screen main">
      <ScrollToTop setPath={setPath} />
      <Head path={path} setPath={setPath} />
      <div className="main">
        <Outlet />
      </div>
      <Footer path={path} setPath={setPath} />
    </div>
  );
}

export default Layout;
