import { FormControl, MenuItem, Select, InputAdornment } from "@mui/material";
import SearchInput from "../search/Search";
import { useTranslation } from "react-i18next";
import { MdOutlineClear } from "react-icons/md";
import styled from "./styles.module.scss";
import clsx from "clsx";

const SearchMedia = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto flex flex-col grid-flow-row lg:grid lg:grid-cols-5 justify-center items-start gap-4">
      <div className="w-full">
        <p className="text-zinc-500 text-sm font-normal mb-1">
          {t("pubcategory")}:
        </p>
        <FormControl fullWidth className={styled.custom_box}>
          <Select
            sx={{
              fontFamily: "TitilliumWebsemiblod",
              color: "#C2C2C2",
              fontStyle: "normal",
              fontWeight: "400",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#30716E",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#30716E !important",
              },
              "& .MuiSelect-iconOutlined": {
                display: props.category ? "none" : "",
              },
              "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
            }}
            value={props.category}
            displayEmpty
            onChange={props.handleChangeCategory}
            endAdornment={
              props.category && (
                <InputAdornment position="end" className="cursor-pointer">
                  <MdOutlineClear
                    onClick={() => {
                      props.handleChangeCategory("");
                    }}
                  />
                </InputAdornment>
              )
            }
          >
            <MenuItem disabled value="">
              <em>{t("pubcategory")}</em>
            </MenuItem>
            {props.categorys?.map((category: any) => (
              <MenuItem key={category?.id} value={category?.id}>
                {category?.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="w-full">
        <label className="text-zinc-500 text-sm font-normal mb-1">
          {t("year")}
        </label>
        <FormControl fullWidth className={styled.custom_box}>
          <Select
            sx={{
              fontFamily: "TitilliumWebsemiblod",
              color: "#C2C2C2",
              fontStyle: "normal",
              fontWeight: "400",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#30716E",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#30716E !important",
              },
              "& .MuiSelect-iconOutlined": {
                display: props.year ? "none" : "",
              },
              "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
            }}
            placeholder={t("year")}
            value={props.year}
            displayEmpty
            onChange={(event) => props.handleChange(event, true)}
            endAdornment={
              props.year && (
                <InputAdornment position="end" className="cursor-pointer">
                  <MdOutlineClear
                    onClick={() => {
                      props.handleChange("", true);
                    }}
                  />
                </InputAdornment>
              )
            }
          >
            <MenuItem disabled value="">
              <em>{t("year")}</em>
            </MenuItem>
            {props?.years
              ?.sort(
                (item1: any, item2: any) =>
                  Number(item2?.title) - Number(item1?.title)
              )
              ?.map((year: any) => (
                <MenuItem
                  key={year?.title}
                  value={year?.title}
                  className="no-dec"
                >
                  {year?.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <SearchInput
        className={clsx(
          "lg:col-span-3 order-first lg:order-last mt-6",
          styled.custom_box
        )}
        search={props.search}
        handleSearch={props.filter}
        handleChange={props.handleChange}
        setSearch={props.setSearch}
        isMedia
      />
    </div>
  );
};

export default SearchMedia;
