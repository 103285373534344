import React, { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./Footer.module.scss";
import InfiniteScrollBar from "../../components/infiniteScrollBar/infiniteScrollBar";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Footer({ path, setPath }: any) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [itemFooter, setItemFooter] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  async function Apielement() {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_WORDPRESS}/footer?_fields=acf.footer_${language}`
      )
      .then((response) => {
        setItemFooter(response?.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    Apielement();
  }, [language]);
  return (
    <footer
      className={clsx(
        styles.container,
        "flex flex-col items-stretch mt-auto relative"
      )}
    >
      <InfiniteScrollBar />
      <div className="fontfooter  bg-green-primary border-green-secondary text-gray-200">
        <div className="mx-auto w-full flex flex-col justify-between pt-8 pb-4 container">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-4 text-white text-[16px] font-normal pb-4 lg:pb-0">
            {!loading &&
              itemFooter?.toReversed()?.map((item: any, index: number) => {
                return index !== 0 ? (
                  itemFooter?.length === index + 1 ? (
                    <div
                      key={index}
                      className="flex flex-col gap-2 sm:col-span-3"
                    >
                      <h2 className="mb-2 font-semibold text-green-secondary uppercase w-fit">
                        {item?.acf?.["footer_" + language]?.title}
                      </h2>
                      <p className=" text-white opacity-60 w-fit">
                        {item?.acf?.["footer_" + language]?.line1}
                      </p>
                      <p className="text-white opacity-60 w-fit">
                        {item?.acf?.["footer_" + language]?.line2}
                      </p>
                      <p className="text-white opacity-60 w-fit">
                        {item?.acf?.["footer_" + language]?.line3}
                      </p>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="flex flex-col gap-2 sm:col-span-3"
                    >
                      <h2 className="mb-2 font-semibold text-green-secondary uppercase w-fit">
                        {item?.acf?.["footer_" + language]?.title}
                      </h2>
                      <a
                        className={clsx(
                          path?.toLowerCase() ===
                            item?.acf?.[
                              "footer_" + language
                            ]?.line1_url?.toLowerCase()
                            ? "underline opacity-100"
                            : "opacity-60",
                          "hover:underline text-white w-fit "
                        )}
                        onClick={() =>
                          setPath(item?.acf?.["footer_" + language]?.line1_url)
                        }
                        key={item?.acf?.["footer_" + language]?.line1_ul}
                        href={item?.acf?.["footer_" + language]?.line1_url}
                      >
                        {item?.acf?.["footer_" + language]?.line1}
                      </a>
                      <a
                        className={clsx(
                          path?.toLowerCase() ===
                            item?.acf?.[
                              "footer_" + language
                            ]?.line2_url?.toLowerCase()
                            ? "underline opacity-100"
                            : "opacity-60",
                          "hover:underline text-white  w-fit"
                        )}
                        onClick={() =>
                          setPath(item?.acf?.["footer_" + language]?.line2_url)
                        }
                        key={item?.acf?.["footer_" + language]?.line2_url}
                        href={item?.acf?.["footer_" + language]?.line2_url}
                      >
                        {item?.acf?.["footer_" + language]?.line2}
                      </a>
                      <a
                        className={clsx(
                          path?.toLowerCase() ===
                            item?.acf?.[
                              "footer_" + language
                            ]?.line3_url?.toLowerCase()
                            ? "underline opacity-100"
                            : "opacity-60",
                          "hover:underline text-white  w-fit"
                        )}
                        onClick={() =>
                          setPath(item?.acf?.["footer_" + language]?.line3_url)
                        }
                        key={item?.acf?.["footer_" + language]?.line3_url}
                        href={item?.acf?.["footer_" + language]?.line3_url}
                      >
                        {item?.acf?.["footer_" + language]?.line3}
                      </a>
                      <a
                        className={clsx(
                          path?.toLowerCase() ===
                            item?.acf?.[
                              "footer_" + language
                            ]?.line4_url?.toLowerCase()
                            ? "underline opacity-100"
                            : "opacity-60",
                          "hover:underline text-white w-fit"
                        )}
                        onClick={() =>
                          setPath(item?.acf?.["footer_" + language]?.line4_url)
                        }
                        key={item?.acf?.["footer_" + language]?.line4_url}
                        href={item?.acf?.["footer_" + language]?.line4_url}
                      >
                        {item?.acf?.["footer_" + language]?.line4}
                      </a>
                    </div>
                  )
                ) : (
                  <div
                    key={index}
                    className="flex flex-col justify-between gap-4 sm:col-span-3"
                  >
                    <span className="text-[16px] font-normal opacity-60 leading-loose md:w-2/3 w-full">
                      {
                        itemFooter?.[itemFooter.length - 1]?.acf?.[
                          "footer_" + language
                        ]?.line1
                      }
                    </span>
                  </div>
                );
              })}
          </div>
          {/* <SocialMedia text={t("follow")} language={language} /> */}
        </div>
        <div className="border-b-2" />
        <div className="w-full text-center py-4">
          <span>{t("copyright")}</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
