import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import {
  Splide,
  SplideTrack,
  Options,
  SplideSlide,
} from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import HighlightCard from "./highlightCard/HighlightCard";
import clsx from "clsx";
import Button from "../button/Button";
const Carousel = ({ header, images, language }: any) => {
  const [imageTab, setImageTab] = useState<any[]>([]);
  const [highlighted, setHighlighted] = useState<any>({});

  const slideRef = useRef<Splide>(null);
  const handleClick = (direction: string | number) => {
    slideRef?.current?.splide?.go(direction);
  };
  const handleImages = () => {
    const tab: any[] = [];
    const key = "learning_solutions_" + language;
    images.forEach(({ acf: { [key]: content } }: any) => {
      content.displayed_bottom ? setHighlighted(content) : tab.push(content);
    });
    setImageTab(tab);
  };
  useEffect(() => {
    handleImages();
  }, [images]);

  const splideOptions: Options = {
    perMove: 1,
    updateOnMove: true,
    cloneStatus: true,
    arrows: false,
    pagination: false,
    speed: 500,
    snap: true,
    rewind: true,
    pauseOnHover: false,
    interval: 2000,
    mediaQuery: "min",
    start: 0,
    padding: "1rem",
    gap: "1rem",
    breakpoints: {
      600: {
        perPage: 2,
      },
      1024: {
        autoplay: false,
        perPage: 3,
      },
      1280: {
        autoplay: false,
        perPage: 4,
      },
      1440: {
        perPage: 5,
      },
    },
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex md:flex-nowrap justify-between items-end gap-4">
        <div className="flex flex-col gap-4 md:flex-row md:justify-between items-end w-full">
          <div className=" flex flex-col gap-4 w-full md:w-4/5">
            <p className="text-green-title text-[28px] font-bold uppercase leading-[42px]">
              {header?.title}
            </p>
            <p className="text-lg font-bold leading-[27px]">
              {header?.description}
            </p>
          </div>
          {header?.qr_code && (
            <div className="w-fit mx-auto">
              <p className="w-36 text-center ">{header?.title_button_pdf}</p>
              <img src={header?.qr_code} alt="qr_code" className="w-36 h-36" />
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx(
          "flex flex-row justify-end gap-2",
          imageTab.length <= 5 && "2xl:hidden"
        )}
      >
        <button onClick={() => handleClick("<")} className="carousel__arrow">
          <IoIosArrowBack className="" />
        </button>
        <button onClick={() => handleClick(">")} className="carousel__arrow">
          <IoIosArrowForward className="" />
        </button>
      </div>
      <Splide ref={slideRef} hasTrack={false} options={splideOptions}>
        <SplideTrack className="images">
          {imageTab?.map((el: any) => (
            <SplideSlide
              key={el.id}
              className="image-slide relative aspect-square"
            >
              <HighlightCard key={el?.id} content={el} />
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
      <HighlightCard content={highlighted} isBig={true} />
      <a className="w-fit m-auto" href={header?.upload_pdf} download>
        <Button density={"dense"}>{header?.title_button_pdf}</Button>
      </a>
    </div>
  );
};
export default Carousel;
