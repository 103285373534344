const Next = ({ etat, pagination }: any) => {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none "
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => etat && pagination(true)}
      className="cursor-pointer"
    >
      <path
        d="M30.625 28.9086C30.7344 28.7992 30.8164 28.6351 30.8164 28.4437C30.8164 28.2797 30.7344 28.1156 30.625 27.9789L26.9062 24.2601C26.7695 24.1508 26.6055 24.0687 26.4414 24.0687C26.25 24.0687 26.0859 24.1508 25.9766 24.2601L25.375 24.889C25.2383 25.0258 25.1836 25.1898 25.1836 25.3539C25.1836 25.5453 25.2383 25.7094 25.375 25.8187L28 28.4437L25.375 31.0687C25.2383 31.2054 25.1836 31.3695 25.1836 31.5336C25.1836 31.725 25.2383 31.889 25.375 31.9984L25.9766 32.6273C26.0859 32.764 26.25 32.8187 26.4414 32.8187C26.6055 32.8187 26.7695 32.764 26.9062 32.6273L30.625 28.9086Z"
        fill={etat ? "#30716E" : "#B3CFD0"}
      />
      <rect
        x="0.5"
        y="1.19373"
        width="55"
        height="55"
        rx="27.5"
        stroke={etat ? "#30716E" : "#B3CFD0"}
      />
    </svg>
  );
};

export default Next;
