export const GetLanguage = () => {
  const languageNavg =
    window.navigator.language === "fr-FR" || window.navigator.language === "fr"
      ? "fr"
      : "en";
  const language = localStorage.getItem("i18nextLng");
  if (language) {
    if (language !== languageNavg) {
      return language;
    } else {
      return languageNavg;
    }
  } else {
    return languageNavg;
  }
};
