import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Layout from "./layout/Layout";
import {
  Contact,
  OurPartners,
  Error,
  AboutUs,
  Catalogue,
  GalleryCard,
  Event,
  Events,
  Conference,
  Gallery,
  MasterClass,
  Workshop,
  NewsletterCard,
  PressRelease,
  Publication,
  PressReleases,
  Publications,
  NewsLetters,
  Conferences,
  Workshops,
  Webinar,
  MasterClasses,
  Webinars,
  PrivacyNotice,
} from "./pages";
import AfCFTA from "./pages/AfCFTA";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "our-partners",
        element: <OurPartners />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
      {
        path: "",
        element: <AboutUs />,
      },
      {
        path: "catalogue",
        element: <Catalogue />,
      },
      {
        path: "afcfta",
        element: <AfCFTA />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "gallery/:id",
        element: <GalleryCard />,
      },
      // {
      //   path: "event",
      //   element: <Events />,
      // },
      // {
      //   path: "event/:id",
      //   element: <Event />,
      // },
      // {
      //   path: "webinars",
      //   element: <Webinars />,
      // },
      // {
      //   path: "webinars/:id",
      //   element: <Webinar />,
      // },
      // {
      //   path: "conferences",
      //   element: <Conferences />,
      // },
      // {
      //   path: "conferences/:id",
      //   element: <Conference />,
      // },
      // {
      //   path: "masterclasses",
      //   element: <MasterClasses />,
      // },
      // {
      //   path: "masterclasses/:id",
      //   element: <MasterClass />,
      // },
      // {
      //   path: "workshops",
      //   element: <Workshops />,
      // },
      // {
      //   path: "workshops/:id",
      //   element: <Workshop />,
      // },
      {
        path: "press-releases/:id",
        element: <PressRelease />,
      },
      {
        path: "press-releases",
        element: <PressReleases />,
      },
      {
        path: "publications",
        element: <Publications />,
      },
      {
        path: "publications/:id",
        element: <Publication />,
      },
      {
        path: "newsletters",
        element: <NewsLetters />,
      },
      {
        path: "newsletters/:id",
        element: <NewsletterCard />,
      },
      {
        path: "privacy-and-terms",
        element: <PrivacyNotice />,
      },
    ],
    ErrorBoundary: Error,
  },
]);
function App() {
  return (
    <main className="relative flex-1 h-full">
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </main>
  );
}
export default App;
