import React from "react";
import ReactPlayer from "react-player";
import { Slide, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { GrFormClose } from "react-icons/gr";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Player = ({ content, setContent }: any) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      onClose={() => setContent(null)}
      TransitionComponent={Transition}
      keepMounted
      open={!!content?.video}
      maxWidth="lg"
      fullWidth={fullScreen}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <DialogTitle className="flex flex-nowrap justify-between font-bold">
        {content?.name} ({content?.tranee})
        <GrFormClose
          className="float-right mb-2 text-[27px] cursor-pointer"
          onClick={() => {
            setContent(null);
          }}
        />
      </DialogTitle>
      <DialogContent>
        <ReactPlayer
          url={content?.video}
          controls
          className="w-full h-full md:w-auto md:h-auto"
        />
      </DialogContent>
    </Dialog>
  );
};

export default Player;
