import clsx from "clsx";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import defaultimg from "../../../assets/images/default2.svg";
const Card = (props: any) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-3">
      <img
        src={props.item?.image !== "false" ? props.item?.image : defaultimg}
        alt="image content"
        loading="lazy"
        className={clsx(
          "w-full h-full col-span-1",
          props.index === 0 ? "col-span-2" : "col-span-1",
          props.item?.image !== "false" && "aspect-video"
        )}
      />
      <div
        className={clsx(
          "flex flex-col gap-3 mt-4 lg:mt-0",
          props.index === 0 ? "col-span-2 lg:col-span-2" : "col-span-3"
        )}
      >
        <div className="md:flex flex-row item-center gap-3 ">
          <Link to={"/press-releases/" + props?.item?.id}>
            <div className={styles.cont}>
              <span className="text-teal-700 capitalize mr-2">
                {props?.item?.date &&
                  new Date(props.item?.date).toLocaleDateString([language], {
                    month: "long",
                    year: "numeric",
                  })}
              </span>
              {props.item?.title}
            </div>
          </Link>
        </div>
        <div
          className={clsx(
            props.index !== 0 ? " line-clamp-4" : " line-clamp-6",
            "font text-zinc-500 text-xs font-normal"
          )}
          dangerouslySetInnerHTML={{
            __html: props.item?.description,
          }}
        />
      </div>
    </div>
  );
};

export default Card;
