import React, { useState, useEffect } from "react";
import Box from "../sections/aboutus/box";
import logo from "../assets/images/image.png";
import styles from "./styles.module.scss";
import PresidentStatement from "../sections/aboutus/PresidentStatement";
import Testimonials from "../sections/aboutus/testimonials ";
import Button from "../components/button/Button";
import motif8 from "../assets/images/motif8.png";
import { useTranslation } from "react-i18next";
import InfiniteScrollBar from "../components/loader/Loader";
import { useMediaQuery } from "react-responsive";
import fetchdata from "../service/aboutus";
import image from "../assets/images/bg-aboutus.svg";
import clsx from "clsx";
import "react-lazy-load-image-component/src/effects/blur.css";
import Hero from "../components/hero/hero";

function AboutUs() {
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const [loading, setLoading] = useState(true);
  const [hero, setHero] = useState<any>({});
  const [itemBody, setItemBody] = useState<any>({});
  const [itemLearning1, setItemLearning1] = useState<any[]>([]);
  const [itemPresident, setItemPresident] = useState<any[]>([]);
  const [itemTestimonials, setItemTestimonials] = useState<any[]>([]);
  const [onHover, setOnHover] = useState(false);
  const [test, setTest] = useState<any>({});
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    fetchdata(
      language,
      setHero,
      setItemBody,
      setItemLearning1,
      setItemPresident,
      setItemTestimonials,
      setLoading
    );
  }, [language]);
  if (loading) return <InfiniteScrollBar />;
  return (
    <div>
      <div className="relative flex flex-col gap-2 md:gap-5">
        <section className="hidden lg:block relative">
          <Hero hero={"aboutus"}>
            <foreignObject
              x="121.12"
              y="100"
              width="100%"
              height="100%"
              className="overflow-hidden flex flex-col gap-4 w-1/2"
            >
              <h1
                style={{ color: hero?.color_title_1 }}
                className="text-lg font-extrabold text-[#26FFBA]"
              >
                {hero?.title_1_}
              </h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: hero?.title_2,
                }}
              />
              <Button density={"dense"} className="text-4">
                <a href={hero?.button_url}>{hero?.button_text}</a>
              </Button>
            </foreignObject>
          </Hero>
        </section>
        <section className="bg-green-primary block lg:hidden relative">
          <div className="container section-paddings mx-auto flex flex-col gap-4 h-full z-10 uppercase ">
            <h1
              style={{ color: hero?.color_title_1 }}
              className="text-[20px] xl:text-2xl font-bold text-green-secondary"
            >
              {hero?.title_1_}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: hero?.title_2,
              }}
              className={styles.textfont}
            />
            <div className="flex justify-start">
              <Button density={"dense"} className="text-4">
                <a href={hero?.button_url}>{hero?.button_text}</a>
              </Button>
            </div>
          </div>
        </section>
        <section className="section-paddings">
          <img
            src={image}
            alt="image background"
            className={clsx(
              styles?.["home-bg"],
              "hidden lg:block absolute -z-10"
            )}
            loading="lazy"
          />
          <div className="container flex flex-col gap-8">
            <br />
            {itemBody?.toReversed()?.map((item: any, index: number) => (
              <Box key={item.title_1} item={item} index={index} />
            ))}
          </div>
        </section>
        <section className="relative">
          <div className="flex justify-center items-center relative overflow-hidden section-paddings">
            <div className={styles.oval} />
            <div className="container mx-auto flex flex-col justify-center items-center gap-4">
              <h5 className="text-green-primary text-2xl font-normal uppercase">
                {t("learning")}
              </h5>
              <h6 className="text-4xl font-bold capitalize leading-[60px]">
                {t("learningtitle2")}
              </h6>
              <div className="grid grid-col lg:grid-cols-3 gap-4 relative w-full">
                {itemLearning1.slice(0, 3)?.map(({ id, acf }, index) => (
                  <div
                    key={id}
                    className="relative w-full hover:lg:scale-120 transform transition-transform duration-300 ease-in"
                    onMouseEnter={() => {
                      setOnHover(true);
                      setTest({ id, acf, index });
                    }}
                    onMouseLeave={() => setOnHover(false)}
                  >
                    <img
                      src={acf?.["learning_" + language]?.image}
                      alt="image Learning"
                      className="h-full w-full"
                      loading="lazy"
                    />
                    {(test.index === index && onHover) || isMobile ? (
                      <div className="lg:opacity-25 hover:lg:opacity-100 transition-opacity duration-500 ease-in absolute  text-white top-0 bottom-0 p-2 m-4 md:mx-3 bg-black bg-opacity-30 flex flex-col md:gap-2 justify-center items-center w-[91%] lg:w-[94%] md:w-[96%]">
                        <h4 className="text-center text-lg md:text-2xl font-bold leading-loose tracking-wider">
                          {acf?.["learning_" + language]?.title}
                        </h4>
                        <p className="text-center text-teal-400 text-base md:text-xl font-bold">
                          {acf?.["learning_" + language]?.sous_titre}
                        </p>
                      </div>
                    ) : (
                      <h4 className="absolute w-full text-center text-white top-0 bottom-0 text-lg md:text-2xl font-bold leading-loose tracking-wider flex justify-center items-center">
                        {acf?.["learning_" + language]?.title}
                      </h4>
                    )}
                  </div>
                ))}
              </div>
              <div className="grid grid-col lg:grid-cols-2 gap-4 relative w-full">
                {itemLearning1.slice(3, 5)?.map(({ id, acf }, index) => (
                  <div
                    key={id}
                    className="relative w-full"
                    onMouseEnter={() => {
                      setOnHover(true);
                      setTest({ id, acf, index: index + 3 });
                    }}
                    onMouseLeave={() => setOnHover(false)}
                  >
                    <img
                      src={acf?.["learning_" + language]?.image}
                      alt="image learning"
                      className="h-full w-full"
                      loading="lazy"
                    />
                    {(test.index === index + 3 && onHover) || isMobile ? (
                      <div className="lg:opacity-25 hover:lg:opacity-100 transition-opacity duration-500 ease-in absolute  text-white top-0 bottom-0 p-2 m-4 md:mx-3 bg-black bg-opacity-30 flex flex-col md:gap-2 justify-center items-center w-[91%] md:w-[96%] ">
                        <h4 className="text-center text-lg md:text-2xl font-bold leading-loose tracking-wider">
                          {acf?.["learning_" + language]?.title}
                        </h4>
                        <p className="text-center text-teal-400 text-base md:text-xl font-bold w-full">
                          {acf?.["learning_" + language]?.sous_titre}
                        </p>
                      </div>
                    ) : (
                      <h4 className="absolute text-center w-full text-white top-0 bottom-0  text-lg md:text-2xl font-bold leading-loose tracking-wider flex justify-center items-center">
                        {acf?.["learning_" + language]?.title}
                      </h4>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <img
            src={motif8}
            alt="motif image"
            className="absolute hidden lg:block right-4 top-[80%] w-[15%]"
            loading="lazy"
          />
        </section>
        <PresidentStatement item={itemPresident} />
        <section className="relative">
          <div className="container mx-auto">
            <img
              src={logo}
              alt="image motif"
              width="50%"
              className="hidden md:block m-auto"
              loading="lazy"
            />
          </div>
        </section>
        <Testimonials items={itemTestimonials} />
      </div>
    </div>
  );
}

export default AboutUs;
