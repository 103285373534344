import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import SocialMediaShare from "../../../components/socialMedia/SocialMediaShare";
import logo from "../../../assets/images/heropress.png";
import motif5 from "../../../assets/images/motif5.svg";
import motif4 from "../../../assets/images/motif4.png";
import { BsCalendar } from "react-icons/bs";
import InfiniteScrollBar from "../../../components/loader/Loader";
import defaultimg from "../../../assets/images/default2.svg";
import clsx from "clsx";
import { fetchdatadetails } from "../../../service/publication";

const Publication = () => {
  const { id } = useParams();
  const [item, setItem] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    fetchdatadetails(language, id || "", setLoading, setItem, navigate);
  }, [language, id]);
  return (
    <div>
      {loading ? (
        <InfiniteScrollBar />
      ) : (
        <>
          <section className="bg-green-primary lg:bg-transparent relative">
            <img
              src={logo}
              alt=""
              className="hidden lg:block w-full lg:absolute top-0 -z-10 h-full"
            />
            <div className="section-paddings container mx-auto flex flex-col lg:gap-10 gap-4 h-full lg:px-60">
              <div className="relative">
                <div className="flex flex-row justify-between">
                  <h1 className="text-green-secondary text-xl font-normal uppercase tracking-wide">
                    Publication
                  </h1>
                  <Link to="/publications">
                    <button className="flex flex-row justify-center items-center bg-transparent border-transparent cursor-pointer">
                      <MdArrowBackIos color="white" />
                      <p className="text-white text-xl font-medium">
                        {t("back")}
                      </p>
                    </button>
                  </Link>
                </div>
                <div className="lg:grid flex flex-col-reverse lg:grid-cols-2 gap-4 mt-5">
                  <div className="w-full h-fit relative">
                    <img
                      src={
                        item?.hero?.image !== "false"
                          ? item?.hero?.image
                          : defaultimg
                      }
                      alt="hero"
                      loading="lazy"
                      className={clsx(
                        item?.hero?.image !== "false" && "lg:w-9/12",
                        "object-cover  w-full  ml-auto"
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    {item?.hero?.date && (
                      <div className="flex flex-row gap-4 items-center justify-end">
                        <BsCalendar color="#26FFBA" />
                        <p className="text-green-secondary text-lg font-medium">
                          {new Date(item?.hero?.date)?.toLocaleDateString(
                            [language],
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </p>
                      </div>
                    )}
                    {item?.hero?.styled_title && (
                      <div
                        className="textfontsubtitle w-5/6"
                        dangerouslySetInnerHTML={{
                          __html: item?.hero?.styled_title,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br className="hidden lg:block" />
            </div>
          </section>
          <section className="section-paddings no-tp">
            <div className="container mx-auto lg:px-60">
              {item?.hero?.description && (
                <p className="font text-justify text-sm font-normal leading-7 mb-6">
                  {item?.hero?.description}
                </p>
              )}
              {item?.title_button_pdf && item?.pdf && (
                <a
                  target="_blank"
                  href={item?.pdf}
                  className="w-fit float-right"
                  rel="noreferrer"
                >
                  <Button density={"dense"} className="whitespace-nowrap">
                    {item?.title_button_pdf}
                  </Button>
                </a>
              )}
              <br />
              <br />
              <br />
              <SocialMediaShare color="#006666" />
              <img
                src={motif5}
                alt="motif image"
                loading="lazy"
                className="hidden md:block float-right  mb-3"
              />
            </div>
          </section>
          {item?.button_link &&
            item?.button &&
            item?.description &&
            item?.title &&
            item?.image && (
              <section className="section-paddings ">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 lg:px-60">
                  <div className="flex flex-col justify-center items-center gap-4 p-8 border">
                    <h5 className="text-teal-700 text-4xl font-normal capitalize">
                      {item?.title}
                    </h5>
                    <p className="text-zinc-500 text-base font-normal leading-loose">
                      {item?.description}
                    </p>
                    <a href={item?.button_link}>
                      <Button
                        density={"dense"}
                        className="mb-5 whitespace-nowrap"
                      >
                        {item?.button}
                      </Button>
                    </a>
                  </div>
                  <img
                    src={item?.image}
                    alt="image content"
                    loading="lazy"
                    className="w-full"
                  />
                </div>
              </section>
            )}
        </>
      )}
    </div>
  );
};

export default Publication;
