import React from "react";
import errorImage from "../assets/images/404.png";
import Layout from "../layout/Layout";
import Button from "../components/button/Button";
import { useTranslation } from "react-i18next";
const ErrorComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="section-paddings relative container text-center text-zinc-600 flex flex-col justify-center items-center gap-4 bg-white">
      <img src={errorImage} alt="404" className="lg:max-w-[40vw]" />
      <h1 className="text-3xl"> {t("404_TITLE")}</h1>
      <h4 className="text-base">{t("404_DESC_1")}</h4>
      <h4 className="text-base">{t("404_DESC_2")}</h4>
      <Button density={"dense"}> {t("GO_BACK")}</Button>
    </div>
  );
};

export default ErrorComponent;
