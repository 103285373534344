import axios from "axios";

export async function fechdata(
  loading: Boolean,
  language: string,
  setLoading: any,
  setContents: any,
  setYears: any,
  setLastContent: any
) {
  !loading && setLoading(true);

  Promise.all([
    await axios.post(`${process.env.REACT_APP_NODE}/gallery/filter`, {
      language,
    }),
    axios.get(`${process.env.REACT_APP_NODE}/misc/years_filter`),
    axios.get(`${process.env.REACT_APP_NODE}/gallery/last/${language}`),
  ])
    .then(([res1, res2, res3]) => Promise.all([res1, res2, res3]))
    .then(([items, datayear, last]) => {
      setContents(items?.data);
      setYears(datayear?.data);
      setLastContent(last?.data?.[0]);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function handlerSearch(
  etat: boolean,
  language: string,
  year: any,
  search: any,
  setContents: any
) {
  await axios
    .post(`${process.env.REACT_APP_NODE}/gallery/filter`, {
      language,
      year: year,
      search: etat ? "" : search,
    })
    .then((response) => {
      setContents(response.data);
    })
    .catch((error) => console.log(error));
}
export async function fetchdatadetails(
  language: string,
  id: string,
  setLoading: any,
  setContent: any,
  setOtherContent: any,
  navigate: any
) {
  setLoading(true);
  await Promise.all([
    axios.get(`${process.env.REACT_APP_NODE}/gallery/${language}/${id}`),
    axios.get(`${process.env.REACT_APP_NODE}/gallery/random/${language}`),
  ])
    .then(([res1, res2]) => Promise.all([res1, res2]))
    .then(([data1, random]) => {
      if (data1.data) {
        setContent(data1?.data);
        setOtherContent(random?.data);
        setLoading(false);
      } else {
        return navigate("/gallery");
      }
    });
}
