import axios from "axios";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const Archive = (props: any) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const lng = language === "fr" ? "fr" : "en";
  let archiveTab: any = [];
  const getArchive = () => {
    let today = new Date();
    for (let i = 1; i < 4; i++) {
      today.setMonth(today.getMonth() - 1);
      archiveTab.push(new Date(today));
    }
  };
  getArchive();

  const searchbymonth = async (date: Date) => {
    props?.archive === date.getFullYear() + "-" + (date.getMonth() + 1)
      ? props?.setArchive("")
      : props?.setArchive(date.getFullYear() + "-" + (date.getMonth() + 1));
    props.setSearchTags?.("");
    props?.setCategory?.("");
    props?.setYear?.("");
    props?.setSearch?.("");
    props?.setIsPast?.(true);
    props?.setNbPage?.(0);
    props?.setRange?.([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    await axios
      .post(props.url, {
        lang: lng,
        isPast: true,
        archiveDate:
          props?.archive === date.getFullYear() + "-" + (date.getMonth() + 1)
            ? ""
            : date.getFullYear() + "-" + (date.getMonth() + 1),
        page: 0,
      })
      .then((response) => {
        props.setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="h-fit border-[#00736E] border-solid border-2 p-4 flex flex-col gap-3  lg:order-none">
      <h6 className="text-center text-teal-700 text-xl font-medium uppercase">
        {t("archve")}
      </h6>
      {archiveTab.reverse()?.map((date: Date, index: number) => {
        return (
          <button
            key={index}
            className={clsx(
              props?.archive ===
                date.getFullYear() + "-" + (date.getMonth() + 1)
                ? "text-teal-700"
                : "text-zinc-500",
              "text-center  hover:text-teal-700 text-xl font-bold cursor-pointer capitalize"
            )}
            onClick={async () => {
              searchbymonth(date);
            }}
          >
            {date.toLocaleDateString([language], {
              month: "long",
              year: "numeric",
            })}
          </button>
        );
      })}
    </div>
  );
};

export default Archive;
