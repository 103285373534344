import { Card, CardContent } from "@mui/material";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ContentCard = ({ long = false, className = "", content }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {content?.hero_gallery && (
        <Card className={clsx(styles.card, long && styles.long, className)}>
          <img src={content?.hero_gallery} alt="card image" loading="lazy" />
          <CardContent className={clsx(styles.content)}>
            <p className="">
              {new Date(
                content?.date.split(" ")[0].split("/").reverse()
              ).toLocaleDateString([], {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </p>
            <div className="flex flex-row justify-between">
              <h5 className="text-center text-white text-base font-bold leading-tight tracking-tight">
                {content?.title}
              </h5>
              <div className="mt-auto w-2/5 md:w-1/5 ml-auto flex items-end justify-end">
                <button
                  className={clsx(styles.button)}
                  onClick={() => {
                    navigate(`/gallery/${content.id}`);
                  }}
                >
                  <span className={styles["label-hidden"]}>
                    {t("discover")}
                  </span>
                  <AiOutlineArrowRight
                    className={clsx(styles["icon"], "m-auto text-slate-600")}
                  />
                </button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ContentCard;
