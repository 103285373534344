import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import defaultimg from "../../../assets/images/default2.svg";
const Card = (props: any) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <div
      className={clsx(
        props.index === 0
          ? "flex flex-col lg:grid lg:grid-cols-2  col-span-2 mb-10"
          : "flex flex-col",
        " gap-4"
      )}
    >
      <img
        src={props.item?.image !== "false" ? props.item?.image : defaultimg}
        alt="image content"
        loading="lazy"
        className={clsx(
          "w-full",
          props.item?.image !== "false" && "aspect-video"
        )}
      />
      <div className="flex flex-col gap-4 h-full">
        <p className="text-green-title text-base font-medium">
          {props.item?.date &&
            new Date(props.item?.date).toLocaleDateString([language], {
              month: "long",
              year: "numeric",
            })}
        </p>
        <p className="text-teal-700 text-lg font-medium">{props.item?.title}</p>
        <p className="font line-clamp-3 text-[#737980] text-sm font-normal">
          {props.item?.description}
        </p>
        <div className="flex justify-end mt-auto">
          <Link to={"/publications/" + props.item?.id}>
            <button className="flex flex-row justify-center items-center bg-[#FFCD00] lg:bg-transparent text-[#636363] lg:text-green-secondary  lg:hover:bg-[#FFCD00]  lg:hover:text-[#636363] w-fit p-2 rounded-lg ">
              <p className="text-sm font-medium">{t("readmore")}</p>
              <TrendingFlatIcon className="items-center mt-1" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
