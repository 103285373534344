import { useState, useEffect, useRef } from "react";
import logo from "../../../assets/images/heropress.png";
import image from "../../../assets/images/imagenewsletter.svg";
import { SelectChangeEvent } from "@mui/material";
import axios from "axios";
import Card from "../../../sections/media_publications/newsletters/card";
import SearchMedia from "../../../components/searchMedia/searchMedia";
import Tags from "../../../components/tags/Tags";
import Archive from "../../../components/achive/Archive";
import RelatedArticle from "../../../components/RelatedArticle/RelatedArticle";

import { useTranslation } from "react-i18next";
import InfiniteScrollBar from "../../../components/loader/Loader";
import Next from "../../../components/iconpaginate/Next";
import Past from "../../../components/iconpaginate/Past";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  changecategory,
  fetchdata,
  filter,
  handlerChange,
} from "../../../service/newsletter";
import clsx from "clsx";
import Lottie from "lottie-react";
import nodata from "../../../assets/images/nodata.json";

const NewsLetters = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [item, setItem] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [category, setCategory] = useState("");
  const [years, setYears] = useState("");
  const [categorys, setCategorys] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [searchTags, setSearchTags] = useState<any>();
  const [nbPage, setNbPage] = useState(0);
  const [archive, setArchive] = useState("");
  const [title, setTitle] = useState("");
  const [random, setRandom] = useState<any>([]);
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (event: SelectChangeEvent, isYear = false) => {
    handlerChange(
      event,
      isYear,
      language,
      category,
      search,
      setSearchTags,
      setArchive,
      setYear,
      setItem,
      setSearch
    );
  };
  const handleChangeCategory = (event: SelectChangeEvent) => {
    changecategory(
      event,
      language,
      year,
      search,
      setSearchTags,
      setArchive,
      setCategory,
      setItem
    );
  };
  const handlersearch = (etat: boolean) => {
    filter(
      etat,
      language,
      category,
      year,
      search,
      setSearchTags,
      setArchive,
      setItem
    );
  };
  useEffect(() => {
    fetchdata(
      language,
      setItem,
      setRandom,
      setCategorys,
      setYears,
      setTitle,
      setLoading
    );
  }, [language]);
  const pagination = async (etat: boolean) => {
    setNbPage(() => (etat ? nbPage + 1 : nbPage - 1));
    axios
      .post(`${process.env.REACT_APP_NODE}/newsletters/filter`, {
        page: etat ? nbPage + 1 : nbPage - 1,
        lang: language,
        category: category,
        year: year,
      })
      .then((res) => setItem(res.data))
      .catch((err) => console.log(err));
  };
  if (loading) return <InfiniteScrollBar />;
  return (
    <div>
      <section className="bg-green-primary xl:bg-transparent relative ">
        <img
          src={logo}
          alt="hero image"
          loading="lazy"
          className="hidden xl:block w-full xl:absolute top-0 -z-10 h-full"
        />
        <div className="section-paddings container mx-auto flex flex-row items-center justify-center h-full xl:pb-52">
          <div className="flex flex-col gap-6">
            <h1 className="text-green-secondary text-2xl font-normal uppercase tracking-wide">
              Newsletters
            </h1>
            <div
              className="textfonttitlemedia"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
          <img
            src={image}
            alt="image newsletter"
            loading="lazy"
            className="hidden xl:block"
          />
        </div>
      </section>

      <section className="section-paddings">
        <SearchMedia
          category={category}
          handleChangeCategory={handleChangeCategory}
          categorys={categorys}
          year={year}
          years={years}
          search={search}
          filter={handlersearch}
          handleChange={handleChange}
          setSearch={setSearch}
        />
      </section>
      <section>
        <div className="container flex flex-row justify-end gap-3">
          <Past
            etat={Math.ceil(item?.count / 5) - 1 >= nbPage && nbPage > 0}
            pagination={pagination}
            isPast={true}
          />
          <Next
            etat={Math.ceil(item?.count / 5) - 1 > nbPage}
            pagination={pagination}
          />
        </div>
      </section>
      <section className="section-paddings ">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 grid-flow-row break-words gap-8">
          <div className="lg:col-span-2 row-span-5 grid grid-cols-2 gap-4 h-fit ">
            {item?.result?.length > 0 ? (
              item?.result?.map((item: any, index: number) => (
                <Card item={item} index={index} key={index} />
              ))
            ) : (
              <Lottie
                animationData={nodata}
                loop={true}
                style={{ height: 400 }}
                className="col-span-2"
              />
            )}
            <br />
            {item?.count !== 0 && (
              <div className="col-span-2 border-solid border-t-2 border-[#B3B3B3] flex flex-row justify-between pt-4">
                <button
                  className={clsx(
                    Math.ceil(item?.count / 5) - 1 >= nbPage && nbPage > 0
                      ? " text-[#006666] cursor-pointer"
                      : "text-gray-500 cursor-default",
                    "flex flex-row gap-2 items-center"
                  )}
                  onClick={() =>
                    Math.ceil(item?.count / 5) - 1 >= nbPage &&
                    nbPage > 0 &&
                    pagination(false)
                  }
                >
                  <ChevronLeftIcon fontSize="small" />
                  <span>{t("previous")}</span>
                </button>
                <p className="text-[#006666]">
                  Page {nbPage + 1}/{Math.ceil(item?.count / 5)}
                </p>
                <button
                  className={clsx(
                    Math.ceil(item?.count / 5) - 1 > nbPage
                      ? "text-[#006666] cursor-pointer"
                      : "text-gray-500 cursor-default",
                    "flex flex-row gap-2 items-center "
                  )}
                  onClick={() =>
                    Math.ceil(item?.count / 5) - 1 > nbPage && pagination(true)
                  }
                >
                  <span> {t("next")}</span>
                  <NavigateNextIcon fontSize="small" />
                </button>
              </div>
            )}
          </div>
          <Tags
            setData={setItem}
            url={`${process.env.REACT_APP_NODE}/newsletters/filter`}
            searchTags={searchTags}
            setSearchTags={setSearchTags}
            setCategory={setCategory}
            category={category}
            setYear={setYear}
            year={year}
            setSearch={setSearch}
            setNbPage={setNbPage}
            setArchive={setArchive}
          />
          <Archive
            setData={setItem}
            url={`${process.env.REACT_APP_NODE}/newsletters/filter`}
            setSearchTags={setSearchTags}
            searchTags={searchTags}
            setSearch={setSearch}
            setCategory={setCategory}
            setYear={setYear}
            setNbPage={setNbPage}
            setArchive={setArchive}
            archive={archive}
          />
          <div className="h-fit border-[#00736E] border-solid border-2 p-4 flex flex-col gap-4">
            <h6 className="text-center text-teal-700 text-xl font-medium uppercase">
              {t("relatedarticle")}
            </h6>

            <RelatedArticle random={random} path="/newsletters/" />
          </div>
          <div ref={targetDivRef}></div>
        </div>
      </section>
    </div>
  );
};

export default NewsLetters;
