import axios from "axios";

async function fetchdata(
  language: string,
  setHero: any,
  setItemBody: any,
  setItemLearning1: any,
  setItemPresident: any,
  setItemTestimonials: any,
  setLoading: any
) {
  setLoading(true);
  Promise.all([
    axios.get(`${process.env.REACT_APP_WORDPRESS}/aboutus`),
    axios.get(`${process.env.REACT_APP_WORDPRESS}/learning`),
    axios.get(
      `${process.env.REACT_APP_WORDPRESS}/testimonials?per_page=100000000000000000`
    ),
  ])
    .then(([res1, res2, res3]) =>
      Promise.all([res1.data, res2.data, res3.data])
    )
    .then(([items, learning, testimonials]) => {
      let tabltestimonials: any[] = [];
      testimonials?.map((item: any) =>
        tabltestimonials.push({
          ...item?.acf?.["testimonials_" + language],
          length: item?.acf?.["testimonials_" + language]?.description?.length,
        })
      );

      const tablearn1 = learning.filter(
        (item: any) => item?.acf?.["learning_" + language]?.condition && item
      );
      setHero(items[0]?.acf?.["hero_" + language]);
      setItemBody([
        items[0]?.acf?.["about_us_" + language]?.our_purpose,
        items[0]?.acf?.["about_us_" + language]?.our_vision,
        items[0]?.acf?.["about_us_" + language]?.our_mission,
      ]);
      setItemLearning1(tablearn1);
      setItemPresident(items[0]?.acf?.["president_" + language]);
      setItemTestimonials(tabltestimonials);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
}
export default fetchdata;
