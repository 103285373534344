import { useState, useEffect } from "react";
import Button from "../../../components/button/Button";
import motif6 from "../../../assets/images/motif6.png";
import motif4 from "../../../assets/images/motif4.png";
import logo from "../../../assets/images/heropress.png";
import SocialMediaShare from "../../../components/socialMedia/SocialMediaShare";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { BsCalendar } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import InfiniteScrollBar from "../../../components/loader/Loader";
import { fetchdatadetail } from "../../../service/newsletter";

function NewsletterCard() {
  const { id } = useParams();
  const [content, setContent] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    fetchdatadetail(language, id || "", setContent, setLoading, navigate);
  }, [language, id]);
  return (
    <div>
      {loading ? (
        <InfiniteScrollBar />
      ) : (
        <div className="flex flex-col gap-8">
          <section className="bg-green-primary lg:bg-transparent relative">
            <img
              src={logo}
              alt="hero image"
              loading="lazy"
              className="hidden lg:block w-full lg:absolute top-0 -z-10 h-full"
            />
            <div className="section-paddings container mx-auto flex flex-col lg:gap-10 gap-4 h-full lg:px-80">
              <div className="relative">
                <div className="flex flex-row justify-between">
                  <h1 className="text-green-secondary text-xl font-normal uppercase tracking-wide">
                    Newsletter
                  </h1>
                  <Link to="/newsletters">
                    <button className="flex flex-row justify-center items-center bg-transparent border-transparent cursor-pointer">
                      <MdArrowBackIos color="white" />
                      <p className="text-white text-xl font-medium">
                        {t("back")}
                      </p>
                    </button>
                  </Link>
                </div>
                <div className="flex flex-row gap-4 items-center justify-start pt-3 lg:justify-end">
                  <BsCalendar color="#26FFBA" />
                  <p className="text-green-secondary text-lg font-medium">
                    {new Date(content?.hero?.date)?.toLocaleDateString(
                      [language],
                      {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      }
                    )}
                  </p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-12 mt-5">
                  <div className="lg:ml-auto md:m-auto order-last lg:order-none ">
                    <img
                      src={content?.hero?.image}
                      alt="image newsletter"
                      loading="lazy"
                      className="lg:ml-auto"
                    />
                    <p className="text-white text-sm font-normal leading-[16.80px] w-3/4 mt-2">
                      <span className="text-green-secondary">
                        {content?.hero?.name}
                      </span>
                      ,{content?.hero?.job_title}
                    </p>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content?.hero?.styled_title,
                      }}
                    />
                    <SocialMediaShare color="white" colortext="white" />
                  </div>
                </div>
              </div>
              <img
                src={motif4}
                alt="motif image"
                loading="lazy"
                className="w-1/2 ml-[35%] -mt-[12%] z-10 hidden lg:block pb-20"
              />
            </div>
          </section>
          <section className="section-paddings no-btm">
            <div className="container  justify-center grid grid-cols-1 lg:grid-cols-3 gap-12 ">
              <div className="flex flex-col gap-4">
                <p className="font text-justify text-zinc-600 text-2xl font-bold leading-[38.83px] p-4 border-l-4 border-l-green-title h-fit ">
                  {content.descriptions.block_1}
                </p>
                <p className=" pl-4 text-[#00736E] text-[32px] font-bold leading-[51.78px]">
                  {content?.hero?.name}
                </p>
                <p className=" pl-4 text-green-title text-[22px] font-bold leading-9">
                  {content?.hero?.job_title}
                </p>
                <img
                  src={motif6}
                  alt="motif image"
                  loading="lazy"
                  className="hidden lg:block"
                />
              </div>
              <p className="font text-zinc-500 text-xl font-normal leading-[2.5rem] tracking-wide text-justify">
                {content.descriptions.block_2}
              </p>
              <p className="font text-zinc-500 text-xl font-normal leading-[2.5rem] tracking-wide text-justify">
                {content.descriptions.block_3}
              </p>
            </div>
          </section>
          <section className="section-paddings no-tp">
            <div className="container mx-auto flex flex-col gap-6 ">
              <div className="flex justify-end">
                <a
                  href={content?.descriptions?.button_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    className="float-right whitespace-nowrap"
                    density={"dense"}
                  >
                    {content?.descriptions?.button}
                  </Button>
                </a>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2  mx-auto  max-w-4xl">
                <div className="flex flex-col justify-center items-center gap-4 shadow p-8">
                  <h5 className="text-teal-700 text-4xl font-normal capitalize">
                    {content?.about_us?.title}
                  </h5>
                  <p className="text-zinc-500 text-base font-normal leading-loose ">
                    {content?.about_us?.description}
                  </p>
                  <a href={content?.about_us?.button_link}>
                    <Button
                      density={"dense"}
                      className="mb-5 whitespace-nowrap"
                    >
                      {content?.about_us?.button}
                    </Button>
                  </a>
                </div>
                <img
                  src={content?.about_us?.image}
                  alt="image aboutus"
                  loading="lazy"
                  className="w-full"
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default NewsletterCard;
