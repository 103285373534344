import motif12 from "../../assets/images/motif12.svg";
import { useTranslation } from "react-i18next";

const Statement = (props: any) => {
  const { t } = useTranslation();
  return (
    <section className="section-paddings">
      <div className="container mx-auto flex flex-col gap-4 relative">
        <h6 className="text-[#00736E] text-2xl font-normal uppercase tracking-wide text-left">
          {t("statement")}
        </h6>

        <div className="relative grid lg:grid-cols-3 gap-2 lg:gap-16">
          <div className="lg:col-span-2 mxD-auto grid grid-rows-3 ">
            <p className="text-[28px] font-bold">{props?.statement?.title}</p>
            <p className="text-justify font w-fit text-zinc-600 text-base font-normal row-span-2">
              {props?.statement?.description}
            </p>
          </div>
          <div
            className="w-full relative"
            style={{
              background: `url(${motif12}) no-repeat center contain`,
            }}
          >
            <div className="-z-10 w-40 aspect-square -left-4 -top-4 absolute bg-yellow-300 bg-opacity-40 rounded-full" />
            <img
              src={props?.statement?.image}
              alt="image statement"
              loading="lazy"
            />
            <div className="mt-4">
              <p className="text-green-secondary text-xl leading-10 tracking-wide font-bold">
                {props?.statement?.full_name}
              </p>
              <p className="text-teal-700 text-xl leading-8 tracking-wide font-bold">
                {props?.statement?.profition}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statement;
