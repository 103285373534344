import clsx from "clsx";

const BoxTestImonials = (props: any) => {
  return (
    <div
      className={clsx(
        props?.length?.length === undefined
          ? "min-h-[235px] "
          : "min-h-[300px]",
        "relative rounded-lg flex flex-col gap-2 shadow text-[#636363] p-4 m-2 "
      )}
    >
      <div className=" flex flex-col lg:inline-flex lg:flex-row flex-nowrap gap-4 justify-start h-full">
        <img
          src={props.item.image}
          alt="image testimoial"
          loading="lazy"
          className="rounded-full w-fit aspect-square"
        />
        <div className="flex flex-col gap-2 justify-center">
          <h5 className="text-green-secondary text-xl font-medium">
            {props.item.full_name}
          </h5>
          <p className="text-sm font-normal">{props.item.profition}</p>
        </div>
      </div>
      <div
        className="font text-base font-medium first-letter:capitalize"
        dangerouslySetInnerHTML={{
          __html: props.item.description,
        }}
      />
    </div>
  );
};
export default BoxTestImonials;
