import clsx from "clsx";

const BoxDelivery = (props: any) => {
  return (
    <div
      className={clsx(
        props?.index === 3
          ? "bg-gradient-to-r from-teal-100 to-yellow-100"
          : props?.index === 2
          ? "bg-yellow-400"
          : "bg-teal-400",
        "bg-opacity-20 lg:w-5/12 flex flex-col justify-start items-center gap-5 p-5 rounded-xl border border-black border-opacity-5 "
      )}
    >
      <p
        className={clsx(
          props?.index === 3
            ? "text-green-primary"
            : props?.index === 2
            ? "text-yellow-400"
            : "text-teal-400",
          "fontbold text-center text-lg font-bold uppercase"
        )}
      >
        {props?.data?.["title_box_" + props?.index]}
      </p>
      <p className=" text-center text-base font-normal leading-normal tracking-wide">
        {props?.data?.["description_box_" + props?.index]}
      </p>
    </div>
  );
};

export default BoxDelivery;
