import axios from "axios";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Tags = (props: any) => {
  const [keysToShow, setKeysToShow] = useState<any>([]);
  const [tagSelected, setTagSelected] = useState<any>({
    id: undefined,
    etat: false,
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();

  async function Apielement() {
    await axios
      .get(`${process.env.REACT_APP_NODE}/misc/tags/${language}`)
      .then((response) => {
        setKeysToShow(response.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    Apielement();
  }, [language]);

  const searchbytags = async (id: number) => {
    setTagSelected({
      id: id,
      etat: tagSelected.id === id ? !tagSelected.etat : true,
    });
    props.setSearchTags(id);
    props?.setCategory?.("");
    props?.setYear?.("");
    props?.setSearch?.("");
    props?.setArchive?.("");
    props?.setNbPage?.(0);
    props?.setRange?.([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    await axios
      .post(props.url, {
        lang: language,
        isPast: props?.isPast,
        tag:
          tagSelected.id === id
            ? !tagSelected.etat
              ? id
              : ""
            : props.searchtags === id
            ? ""
            : id,
        page: 0,
      })
      .then((response) => {
        props.setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="h-40 border-[#00736E] border-solid border-2 p-4 flex flex-col gap-4 -order-2 lg:order-none">
      <h6 className="text-center text-teal-700 text-xl font-medium uppercase">
        {t("titletags")}
      </h6>
      <div className="inline-flex flex-wrap gap-2 mx-auto justify-center overflow-y-auto">
        {keysToShow?.map((tag: any, index: any) => (
          <button
            key={index}
            className={clsx(
              tagSelected.id === tag.id &&
                props?.searchtags === tag.id &&
                tagSelected.etat &&
                "bg-emerald-500 text-white",
              "bg-[#DFEFEA] text-emerald-500 text-sm font-medium text-center p-2 rounded cursor-pointer"
            )}
            onClick={() => searchbytags(tag.id)}
          >
            {tag?.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tags;
